import { useEffect, useRef, useState } from 'react'
import { AxiosResponse } from 'axios'
import { DATE_FORMAT, MOBILE_VIEW_BREAKPOINT, toastMessageInitialData } from '../../constants/constants'
import { DatePicker } from 'antd'
import { getStaffId } from '../../helpers/utils'
import { JTranslation } from '../../helpers/jTranslate'
import { PublishedSchedule, ScheduleData, ScheduleIdsResponse, fetchDraftedAndPublishedScheduleIds, fetchStaffsWithSchedulesAndShift, fetchUserPublishedListForWeek, getWeekRange } from '../../helpers/workTimeScheduleHelper'
import { ToastMessageProps } from '../../constants/staticTypes'
import { MOBILE_USER_PUBLISHED_LIST_FOR_WEEK, SCHEDULE_STATUS, STAFFS_WITH_SCHEDULES_AND_SHIFT, USER_PUBLISHED_LIST_FOR_WEEK } from '../../constants/queryKeys'
import { useWindowSize } from 'react-use'
import { WORK_TIME_SCHEDULE } from '../../constants/strings'
import dayjs from 'dayjs'
import StaffSchedule from '../../components/staff_schedule/StaffSchedule'
import StaffScheduleMobileView from '../../components/staff_schedule/StaffScheduleMobileView'
import ToastAlert from '../../components/alert/ToastAlert'
import useQueryHook from '../../hooks/useQueryHook'
import withSidebar from '../../hoc/withSidebar'
import { TabView, TabPanel } from 'primereact/tabview'
import { isEmptyObject } from 'jquery'
import SwapScheduleView from '../../components/shift_swap/SwapScheduleView'
import { Button } from 'primereact/button'

const StaffScheduleView = () => {
    const { width } = useWindowSize()
    const breakPoint = MOBILE_VIEW_BREAKPOINT
    const staffId = getStaffId()

    const [selectedWeek, setSelectedWeek] = useState<dayjs.Dayjs>(dayjs())
    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
    const [scheduleData, setScheduleData] = useState<PublishedSchedule[]>([]);
    const [mobileScheduleData, setMobileScheduleData] = useState<PublishedSchedule[]>([]);
    const [activeIndex, setActiveIndex] = useState(0)
    const dateRange = getWeekRange(selectedWeek); // Calculate the date range for the selected week
    const [staffsWithSchedule, setStaffsWithSchedule] = useState<ScheduleData | null>(null)
    const [publishedScheduleId, setPublishedScheduleId] = useState('')
	const [showPreview, setShowPreview] = useState(false)

    const onSuccess = (res: AxiosResponse<any, any>) => {
        const publishedSchedule: PublishedSchedule[] = res.data.data
        setScheduleData(publishedSchedule)
    }

    const onMobileSuccess = (res: AxiosResponse<any, any>) => {
        const publishedSchedule: PublishedSchedule[] = res.data.data
        setMobileScheduleData(publishedSchedule)
    }

    const scheduleQuery = useQueryHook(USER_PUBLISHED_LIST_FOR_WEEK, () => fetchUserPublishedListForWeek({ tenantStaffId: staffId, dateRange: dateRange, separateBySchedule: true }), onSuccess)

    const mobileScheduleQuery = useQueryHook(MOBILE_USER_PUBLISHED_LIST_FOR_WEEK, () => fetchUserPublishedListForWeek({ tenantStaffId: staffId, dateRange: dateRange }), onMobileSuccess)
    
    const onSuccessOfScheduleStatus = (res: AxiosResponse<any, any>) => {
        const scheduleData: ScheduleIdsResponse = res.data.data
        const { published } = scheduleData
        
        // Check if draft and published schedules are empty
        const isPublishedEmpty = isEmptyObject(published)

        // If published is not empty, save the published's id using savePublishedScheduleId
            if (!isPublishedEmpty) {
                setPublishedScheduleId(published.id)
        } else {
            setPublishedScheduleId("")
            setStaffsWithSchedule(null)
        }
    }

    const onSuccessOfStaffsWithSchedule = (res: AxiosResponse<any, any>) => {
        const staffsWithSchedule: ScheduleData = res.data.data
        setStaffsWithSchedule(staffsWithSchedule)
    }

    // schedule data main QUERY-1
    const scheduleDataQuery = useQueryHook(
        SCHEDULE_STATUS,
        () => fetchDraftedAndPublishedScheduleIds(dateRange),
        onSuccessOfScheduleStatus,
        (error) => console.log(`Error fetching draft and published schedule ids.`, error),
        true,
        false,
    )

    // schedule data main QUERY-2
    const staffsWithScheduleQuery = useQueryHook(
        STAFFS_WITH_SCHEDULES_AND_SHIFT,
        () => fetchStaffsWithSchedulesAndShift({ scheduleId: publishedScheduleId }),
        onSuccessOfStaffsWithSchedule,
        undefined,
        !!publishedScheduleId, 
        false
    )

    // refetch the published schedule api once the selected date has changed 
    useEffect(() => {
        scheduleQuery.refetch()
        mobileScheduleQuery.refetch()
        scheduleDataQuery.refetch()
    }, [selectedWeek]) // eslint-disable-line

    useEffect(() => {
        if (publishedScheduleId) {
            staffsWithScheduleQuery.refetch()
        }
    }, [publishedScheduleId])

    return (
        <>
            {/* TOAST MESSAGE COMPONENT */}
            <ToastAlert
                show={toastMessage.show}
                onClose={() => setToastMessage(toastMessageInitialData)}
                message={toastMessage.message}
                variant={toastMessage.variant}
            />
            <div className="page-title d-flex align-items-center">
                <div className="flex-grow-1 d-flex align-items-center">
                    <h5 className='m-0'>
                        <JTranslation typeCase="pascal" text={WORK_TIME_SCHEDULE} />
                    </h5>
                </div>
                <div className="flex-grow-0 align-items-center">
                    {/* Fullscreen preview */}
                    {width >= breakPoint && (
                        <Button
                            icon="pi pi-window-maximize"
                            className=" p-button-rounded p-button-primary p-button-text fs-4 color-primary-custom"
                            title="Fullscreen"
                            onClick={() => {
                                setShowPreview(true)
                            }}
                        />
                    )}
                </div>
            </div>
            <div className={`col-md-12 col-lg-12 mb-3 h-100 ${showPreview ? 'mlx-fullscreen' : ''}`}>
                <div className="card h-100 ">
                    <div className="card-header flex-grow-0">
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                                {width >= breakPoint && (
                                    <Button
                                        hidden={!showPreview}
                                        // size="large"
                                        // raised
                                        rounded
                                        outlined
                                        icon="pi pi-window-minimize"
                                        className="p-button-primary color-primary-custom"
                                        title="Close Preview"
                                        onClick={() => {
                                            setShowPreview(false)
                                        }}
                                    />
                                )}
                            </div>
                            <div className="flex-grow-0 me-2 ms-1">
                                <span>
                                    <DatePicker
                                        onChange={(date, dateString) => {
                                            setSelectedWeek(dayjs(date))
                                        }}
                                        picker="week"
                                        value={selectedWeek}
                                        format={(value) =>
                                            `${dayjs(value).startOf('week').format(DATE_FORMAT)} - ${dayjs(value)
                                                .endOf('week')
                                                .format(DATE_FORMAT)}`
                                        }
                                        allowClear={false}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="card-body flex-grow-1 overflow-auto">
                        <div className="survey-section h-100">
                            <TabView
                                className={`staff-schedule-panel mt-2 w-100 settings-tab ${width >= breakPoint ? 'hide-tab-arrow' : ''}`}
                                activeIndex={activeIndex}
                                onTabChange={(e) => setActiveIndex(e.index)}
                                scrollable={true}
                            >
                                <TabPanel
                                    className='test-name'
                                    header={<JTranslation typeCase="pascal" text={'My Schedule'} />}
                                >
                                    {/* switch web and mobile components */}
                                    {width >= breakPoint
                                        ? <StaffSchedule selectedWeek={selectedWeek} scheduleData={scheduleData} isFetching={scheduleQuery.isFetching} />
                                        : <StaffScheduleMobileView 
                                            selectedWeek={selectedWeek} 
                                            scheduleData={mobileScheduleData} 
                                            holidays={staffsWithSchedule?.holidays ?? []}
                                            mandatoryDays={staffsWithSchedule?.mandatoryDays ?? []}
                                            isFetching={mobileScheduleQuery.isFetching || staffsWithScheduleQuery.isFetching} 
                                        />
                                    }
                                </TabPanel>
                                <TabPanel
                                    header={<JTranslation typeCase="pascal" text={'Full Schedule'} />}
                                >
                                    <SwapScheduleView
                                        staffsWithSchedule={staffsWithSchedule}
                                        staffsWithScheduleQuery={staffsWithScheduleQuery}
                                        scheduleDataQuery={scheduleDataQuery}
                                        selectedWeek={selectedWeek}
                                        parent='myScheduleView'
                                    />
                                </TabPanel>
                            </TabView>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withSidebar(StaffScheduleView)
