import { AxiosResponse } from 'axios'
import { getErrorMessage } from './utils'
import { HttpMethods, AlertVariant } from '../constants/constants'
import { BusinessDefaultHours, BusinessHoursType, GoogleAddress, LocationDetails, Payload, TimezoneInfo, TimezoneInfoFormData } from '../constants/staticTypes'
import { updateDateSettingsApi } from '../constants/apiEndPoints'
import { UseMutationResult } from 'react-query'
import * as momentTz from 'moment-timezone'
import * as Yup from 'yup'
import { formatTimeForApi } from './newsAndEventsHelper'
import dayjs from 'dayjs'

export const hasInvalidEndTimeError = (data: Record<string, any>): boolean => {
	// Loop through each day in the data object
	for (const day in data) {
		if (data.hasOwnProperty(day)) {
			// Check if the day has working hours
			const workingHours = data[day]?.workingHours;
			if (Array.isArray(workingHours)) {
				// Loop through each working hour object for the day
				for (const hour of workingHours) {
					// Check if the "endTime" error is present and matches the specific error message
					if (hour.endTime === "End time must be greater than start time") {
						return true;
					}
				}
			}
		}
	}
	return false;
};

export const businessHours: BusinessDefaultHours[] = [
	{
		isOpen: false,
		dayId: 0,
		workingHours: [{ startTime: '', endTime: '', note: '' }],
	}
];

export const businessHoursInitialValues: BusinessHoursType = {
	sunday: { isOpen: false, dayId: 0, workingHours: [{ startTime: '', endTime: '', note: '' }] },
	monday: { isOpen: false, dayId: 1, workingHours: [{ startTime: '', endTime: '', note: '' }] },
	tuesday: { isOpen: false, dayId: 2, workingHours: [{ startTime: '', endTime: '', note: '' }] },
	wednesday: { isOpen: false, dayId: 3, workingHours: [{ startTime: '', endTime: '', note: '' }] },
	thursday: { isOpen: false, dayId: 4, workingHours: [{ startTime: '', endTime: '', note: '' }] },
	friday: { isOpen: false, dayId: 5, workingHours: [{ startTime: '', endTime: '', note: '' }] },
	saturday: { isOpen: false, dayId: 6, workingHours: [{ startTime: '', endTime: '', note: '' }] },
}

export const initialLocationDetails: LocationDetails = {
	city: '',
	country: '',
	formattedAddress: '',
	latitude: 0,
	longitude: 0,
	placeId: '',
	state: '',
	streetAddress: '',
	zip: ''
}

const validateTimeFormat = (time: string | undefined) => {
	const defaultFormat = dayjs(time);
	const customFormat = dayjs(time, 'HH:mm:ss', true);

	return defaultFormat.isValid() || customFormat.isValid();
};

const parseTime = (time: string) => {
	const parsedTime = dayjs(time);
	return parsedTime.isValid() ? parsedTime : dayjs(time, 'HH:mm:ss', true);
};

const daySchema = Yup.object().shape({
	workingHours: Yup.array().of(
		Yup.object().shape({
			startTime: Yup.string()
				.required('Start time is required')
				.test('is-valid-time-format', 'Invalid time format', (value) => validateTimeFormat(value)),
			endTime: Yup.string()
				.required('End time is required')
				.test('is-valid-time-format', 'Invalid time format', (value) => validateTimeFormat(value))
				.test('is-valid-end-time', 'End time must be greater than start time', function (value) {
					const startTime = parseTime(this.parent.startTime);
					const endTime = parseTime(value);
					return endTime.isAfter(startTime);
				}),
			note: Yup.string().optional(),
		})
	),
});

export const businessHoursSchema = Yup.object().shape({
	monday: daySchema,
	tuesday: daySchema,
	wednesday: daySchema,
	thursday: daySchema,
	friday: daySchema,
	saturday: daySchema,
	sunday: daySchema,
});

export const defaultTimezoneInfo: TimezoneInfo = {
	dayLightSavingOffSetInMinutes: 0,
	defaultDateFormat: 'MM/DD/YYYY',
	defaultTimeFormat: 'hh:mm A',
	defaultTimezone: 'America/Los_Angeles',
	isDayLightSaving: false,
	weekStartsOn: 'monday',
}

export function changeBusinessHourDataStructure(businessDefaultHours: BusinessDefaultHours[]): BusinessHoursType {
	const dayMap: Record<number, keyof BusinessHoursType> = {
		0: 'sunday',
		1: 'monday',
		2: 'tuesday',
		3: 'wednesday',
		4: 'thursday',
		5: 'friday',
		6: 'saturday',
	};

	const createDefaultBusinessHours = (): BusinessHoursType => ({
		sunday: { isOpen: false, dayId: 0, workingHours: [{ startTime: '', endTime: '', note: '' }] },
		monday: { isOpen: false, dayId: 1, workingHours: [{ startTime: '', endTime: '', note: '' }] },
		tuesday: { isOpen: false, dayId: 2, workingHours: [{ startTime: '', endTime: '', note: '' }] },
		wednesday: { isOpen: false, dayId: 3, workingHours: [{ startTime: '', endTime: '', note: '' }] },
		thursday: { isOpen: false, dayId: 4, workingHours: [{ startTime: '', endTime: '', note: '' }] },
		friday: { isOpen: false, dayId: 5, workingHours: [{ startTime: '', endTime: '', note: '' }] },
		saturday: { isOpen: false, dayId: 6, workingHours: [{ startTime: '', endTime: '', note: '' }] },
	});

	if (businessDefaultHours.length === 0) {
		return createDefaultBusinessHours();
	}

	const businessHours: BusinessHoursType = createDefaultBusinessHours();

	for (const businessDefaultHour of businessDefaultHours) {
		const { dayId, isOpen, workingHours } = businessDefaultHour;
		const dayName = dayMap[dayId];

		if (dayName) {
			businessHours[dayName] = {
				isOpen,
				dayId,
				workingHours: workingHours.length === 0
					? [{ startTime: '', endTime: '', note: '' }]
					: workingHours,
			};
		}
	}

	return businessHours;
}


// Function to convert form data to API data
export const convertBusinessHoursToApiData = (formData: BusinessHoursType): BusinessDefaultHours[] => {
	const apiData: BusinessDefaultHours[] = [];

	// Define the default structure for each day of the week
	const dayMap: Record<number, keyof BusinessHoursType> = {
		0: 'sunday',
		1: 'monday',
		2: 'tuesday',
		3: 'wednesday',
		4: 'thursday',
		5: 'friday',
		6: 'saturday',
	};

	// Iterate over each day, ensuring all 7 days are included
	for (let dayId = 0; dayId < 7; dayId++) {
		const dayName = dayMap[dayId];
		const dayData = formData[dayName];

		// Format startTime and endTime if they are not empty strings
		const formattedWorkingHours = dayData.workingHours.map((hours) => ({
			...hours,
			startTime: hours.startTime ? formatTimeForApi({ IsoTime: hours.startTime }) : '',
			endTime: hours.endTime ? formatTimeForApi({ IsoTime: hours.endTime }) : '',
		}));

		// Filter out working hours with empty startTime or endTime
		const filteredWorkingHours = formattedWorkingHours.filter(
			(hours) => hours.startTime && hours.endTime
		);

		// Always include the day, even if closed, with default empty working hours if needed
		apiData.push({
			isOpen: dayData.isOpen,
			dayId,
			workingHours: filteredWorkingHours.length > 0
				? filteredWorkingHours
				: [],
		});
	}

	return apiData;
};


export const MomentTimezones = (): { label: string; value: string; example: string }[] => {
	return momentTz.tz.names().map((tz) => {
		return { label: tz, value: tz, example: momentTz.tz(tz).format('MM/DD/YYYY hh:mm A') }
	})
}

export const updateDateSettings = (
	updateDateSettingsMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	param: TimezoneInfoFormData,
	onSuccess: (data: TimezoneInfo) => void,
	onError: (res: string, variant: string) => void
) => {
	updateDateSettingsMutation.mutate(
		{
			url: updateDateSettingsApi,
			method: HttpMethods.PUT,
			data: param,
		},
		{
			onSuccess: (res) => onSuccess(res.data.data),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

export const initialAddress: GoogleAddress = {
	city_town: "",
	country_region: "",
	state: "",
	zip_code: "",
	lat: 0,
	lng: 0,
	place_id: "",
	formatted_address: ""
}