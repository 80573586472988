import { useState, useEffect, useRef, SyntheticEvent } from 'react'
import { Slider } from 'antd'
import type { SliderSingleProps } from 'antd'
import { Menu } from 'primereact/menu'
import { defaultFontScaleValue, FONT_SIZE_COUNT } from '../../constants/constants'
import { changeFontSize } from '../../helpers/utils'
import { useScrolling, useWindowSize } from 'react-use'
import { CHANNEL, RESET_FONT_SCALE } from '../../constants/strings'

const marks: SliderSingleProps['marks'] = {
	0: '-2X',
	25: '-1X',
	50: 'Default',
	75: '1X',
	100: '2X',
}
const fontScales = {
	0: -4,
	25: -2.5,
	50: null,
	75: 2.5,
	100: 4,
}

const FontSlider = () => {
	const { width } = useWindowSize()
	const menuLeft = useRef<any>(null)
	const fontSizeCount = localStorage.getItem(FONT_SIZE_COUNT)
	const [fontScale, setFontScale] = useState<number>(fontSizeCount ? +fontSizeCount : defaultFontScaleValue)
	const [menuEvent, setMenuEvent] = useState<SyntheticEvent | null>(null)
	const items = [
		{
			template: () => {
				return (
					<Slider
						key={fontScale}
						marks={marks}
						step={null}
						included={false}
						defaultValue={fontScale}
						dots={true}
						tooltip={{ open: false }}
						onChange={(value) => {
							setFontScale(value)
						}}
					/>
				)
			},
		},
	]
	const windowScrollElement = document.getElementById('GuestMain') as HTMLElement
	const scrolling = useScrolling({ current: windowScrollElement })

	// make popup slider ui stick with font scale button on resize
	const updateMenuView = () => {
		if (!menuLeft.current) return

		const menuElement = menuLeft.current?.getElement() as HTMLElement
		const buttonElement = menuLeft.current?.getTarget() as HTMLElement

		if (!menuElement || !buttonElement) return

		const currentMenuElementLeft = menuElement.getBoundingClientRect().left
		const currentMenuElementRight = menuElement.getBoundingClientRect().right
		const currentButtonElementRight = buttonElement.getBoundingClientRect().right
		const difference = currentButtonElementRight - currentMenuElementRight
		const centerMenuWidthDifference = menuElement.offsetWidth / 2 - 20

		if (width <= 450) {
			menuElement.style.left = `${currentMenuElementLeft + centerMenuWidthDifference + difference}px`
		} else {
			menuElement.style.left = `${currentMenuElementLeft + difference}px`
		}
	}

	useEffect(() => {
		changeFontSize(null)
		localStorage.setItem(FONT_SIZE_COUNT, fontScale.toString())
		const updatedValue = fontScales[fontScale as keyof typeof fontScales] ?? null
		changeFontSize(updatedValue)
		updateMenuView()
	}, [fontScale, width])

	// hide popup menu on scroll
	useEffect(() => {
		if (scrolling && menuLeft.current && menuEvent) {
			menuLeft.current.hide(menuEvent)
			setMenuEvent(null)
		}
	}, [scrolling])

	useEffect(() => {
		const channel = new BroadcastChannel(CHANNEL)
		channel.onmessage = function (event) {
			if (event.data === RESET_FONT_SCALE) {
                setFontScale(defaultFontScaleValue)
            }
		}
	}, [])

	return (
		<div className="increase-font-container icon-only-link">
			<div className="font-new">
				<Menu
					model={items}
					popup
					ref={(el) => {
						menuLeft.current = el
						if (el) {
							updateMenuView() // Call immediately if element is available
						}
					}}
					id="popup_menu_left"
					popupAlignment="right"
					className="font-slider"
					onShow={(event) => setMenuEvent(event)}
					onHide={(event) => setMenuEvent(event)}
				/>
				<i onClick={(event) => menuLeft.current?.toggle(event)} className="ri-font-size" aria-controls="popup_menu_left" aria-haspopup></i>
				{/* <Button 
					icon="ri-font-size"
					rounded
					outlined
					className="mr-2 font-slider-button"
					onClick={(event) => menuLeft.current?.toggle(event)}
					aria-controls="popup_menu_left"
					aria-haspopup
				></Button> */}
			</div>
		</div>
	)
}

export default FontSlider
