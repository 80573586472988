import React, { useState, useRef, useCallback, useEffect } from 'react';
import { GoogleAddress } from '../../constants/staticTypes';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { initialAddress } from '../../helpers/generalSettingsHelper';

const containerStyle = {
    height: '350px',
};

type Props = {
    addressCallback: (address: GoogleAddress) => void
    address: GoogleAddress
}

function GoogleMaps({ addressCallback, address }: Props) {
    const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY!;
    const { lat, lng } = address

    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [markerPosition, setMarkerPosition] = useState<{ lat: number; lng: number }>({ lat: lat!, lng: lng! });
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [suggestions, setSuggestions] = useState<google.maps.places.AutocompletePrediction[]>([]);
    const inputRef = useRef<HTMLInputElement>(null);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        libraries: ['places'],
    });

    const extractAddressComponents = (mapData: google.maps.GeocoderResult | null): GoogleAddress => {
        if (!mapData) {
            return initialAddress;
        }
        const extracted: GoogleAddress = { ...initialAddress };
        const { address_components, place_id, formatted_address, geometry } = mapData

        address_components.forEach((component) => {
            const types = component?.types;
            extracted.lat = geometry.location.lat as unknown as number;
            extracted.lng = geometry.location.lng as unknown as number;
            extracted.place_id = place_id;
            extracted.formatted_address = formatted_address;

            if (types?.includes("locality")) {
                extracted.city_town = component?.long_name || "";
            } else if (types?.includes("administrative_area_level_1")) {
                extracted.state = component?.long_name || "";
            } else if (types?.includes("country")) {
                extracted.country_region = component?.long_name || "";
            } else if (types?.includes("postal_code")) {
                extracted.zip_code = component?.long_name || "";
            }
        });

        return extracted;
    };

    const getAddress = async (lat: number, lng: number) => {
        try {
            const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAPS_API_KEY}`
            );
            const data = await response.json();
            if (data.status === 'OK' && data.results[0]) {
                const addressData = extractAddressComponents(data.results[0])
                addressCallback(addressData);
            } else {
                addressCallback(initialAddress);
            }
        } catch (error) {
            console.error('Error fetching address:', error);
        }
    };

    const onLoadMap = useCallback((map: google.maps.Map) => {
        const bounds = new window.google.maps.LatLngBounds(markerPosition);
        map.fitBounds(bounds);
        setMap(map);
    }, []);

    const onUnmountMap = useCallback(() => {
        setMap(null);
    }, []);

    const handleInputChange = () => {
        const service = new google.maps.places.AutocompleteService();
        const input = inputRef.current?.value;

        if (input) {
            service.getPlacePredictions(
                { input },
                (predictions: google.maps.places.AutocompletePrediction[] | null, status) => {
                    if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
                        setSuggestions(predictions);
                        setShowSuggestions(true);
                    } else {
                        setSuggestions([]);
                        setShowSuggestions(false);
                    }
                }
            );
        } else {
            setSuggestions([]);
            setShowSuggestions(false);
        }
    };

    const handleMapClick = (event: google.maps.MapMouseEvent) => {
        if (event.latLng) {
            const lat = event.latLng.lat();
            const lng = event.latLng.lng();
            setMarkerPosition({ lat, lng });
            getAddress(lat, lng);
        }
    };

    const handleSuggestionClick = (place_id: string) => {
        if (!map) return;

        const service = new google.maps.places.PlacesService(map);
        service.getDetails(
            {
                placeId: place_id,
                fields: ['geometry', 'formatted_address'],
            },
            (place, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && place?.geometry?.location) {
                    const lat = place.geometry.location.lat();
                    const lng = place.geometry.location.lng();
                    // get address
                    getAddress(lat, lng);

                    // Update marker position
                    setMarkerPosition({ lat, lng });

                    // Update input value
                    if (inputRef.current && place.formatted_address) {
                        inputRef.current.value = place.formatted_address;
                    }

                    // Move map to new location
                    map.panTo({ lat, lng });
                    map.setZoom(15);
                }
            }
        );

        // Clear suggestions
        setSuggestions([]);
        setShowSuggestions(false);
    };

    // add a comment for below use effect
    useEffect(() => {
        if (map && lat && lng) {
            getAddress(lat, lng);
            setMarkerPosition({ lat, lng });

            setTimeout(() => {
                map.panTo({ lat, lng });
                map.setZoom(15);
            }, 300)
        }
    }, [lat, lng, map]); // eslint-disable-line

    return isLoaded ? (
        <div className="w-full relative">

            <div className="map-container">
                <div className="map-search">
                    <input
                        type="text"
                        placeholder="Search for a place"
                        ref={inputRef}
                        onChange={handleInputChange}
                        className="w-full p-2 border rounded"
                        onFocus={() => setShowSuggestions(true)}
                    />
                    {showSuggestions && suggestions.length > 0 && (
                        <div className="absolute w-full bg-white border rounded-b shadow-lg z-10">
                            {suggestions.map((suggestion) => (
                                <div
                                    key={suggestion.place_id}
                                    className="p-2 hover:bg-gray-100 cursor-pointer"
                                    onClick={() => handleSuggestionClick(suggestion.place_id)}
                                >
                                    {suggestion.description}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={markerPosition}
                    zoom={15}
                    onLoad={onLoadMap}
                    onUnmount={onUnmountMap}
                    onClick={handleMapClick}
                >
                    {markerPosition && <Marker position={markerPosition} />}
                </GoogleMap>
            </div>

            {/* {address && <p className="mt-2">Address: {JSON.stringify(address)}</p>} */}
        </div>
    ) : (
        <div>Loading...</div>
    );
}

export default React.memo(GoogleMaps);
