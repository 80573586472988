import React, { useState, useRef, useEffect } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ExtendedShift, PublishedSchedule } from '../../helpers/workTimeScheduleHelper';
import { customDeepClone } from '../../helpers/utils';
import { Badge } from 'primereact/badge';
import { JTranslation } from '../../helpers/jTranslate';
import { checkIfManageSwapRequest, SwapRequestStatus, SwapStaffDetails } from '../../helpers/swapScheduleHelper';
import { useQueryClient } from 'react-query';
import { capitalize } from 'lodash';
import { Divider } from 'primereact/divider';

interface CustomTooltipProps {
    staffName: string;
    content: ExtendedShift[];
    children: JSX.Element;
    showDelay?: number;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ staffName, content, children, showDelay = 0 }) => {
    const queryClient = useQueryClient()
    const managePermission = checkIfManageSwapRequest(queryClient)
    const [isVisible, setIsVisible] = useState(false);
    const timerRef = useRef<number>();
    const overlayRef = useRef<OverlayPanel>(null);

    const showTooltip = (e: any) => {
        timerRef.current = window.setTimeout(() => {
            setIsVisible(true);
            if (overlayRef.current) {
                overlayRef.current.toggle(e);
            }
        }, showDelay);
    };

    const hideTooltip = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        setIsVisible(false);
        if (overlayRef.current) {
            overlayRef.current.hide(); // Hide the OverlayPanel
        }
    };

    const getName = (staff: SwapStaffDetails) => {
        const staffName = staff?.user?.preferredName ?? `${staff?.user?.firstName} ${staff?.user?.lastName}`
        return capitalize(staffName ?? '')
    }

    const swapTemplate = (shift: ExtendedShift) => {
        const updatedShift = customDeepClone(shift) as PublishedSchedule
        const shiftDetails = [...updatedShift?.fromSwapRequests, ...updatedShift?.toSwapRequests]
        const swapShiftDetails = shiftDetails?.find((item) => item?.id === (updatedShift?.assignedShifts?.poolData?.swapRequestId ?? updatedShift?.assignedShifts?.oldData?.swapRequestId))
        const poolData = updatedShift?.assignedShifts?.poolData

        if (!swapShiftDetails) return <></>

        return (
            <div className="m-0">
                <Divider className='p-0 m-1' layout='horizontal' />
                <small className="d-flex flex-column">
                    <div className="tooltip-row">
                        <div className="tooltip-label">
                            <JTranslation typeCase="capitalize" text={'Swap'} />
                        </div>
                        <div className="tooltip-separator">:</div>
                        <div className="tooltip-value">
                            {swapShiftDetails?.requestType === 'swap'
                                ? <JTranslation typeCase="pascal" text={'DIRECT'} />
                                : <JTranslation typeCase="pascal" text={'POOL'} />
                            }
                        </div>
                    </div>
                    {swapShiftDetails?.requestType === 'swap' && (
                        <>
                            <div className="tooltip-row">
                                <div className="tooltip-label">
                                    <JTranslation typeCase="capitalize" text={
                                        getName(
                                            poolData?.toShiftId === swapShiftDetails?.toShiftId
                                                ? swapShiftDetails?.toTenantStaff
                                                : swapShiftDetails?.fromTenantStaff
                                        )} />
                                </div>
                                <div className="tooltip-separator">:</div>
                                <div className="tooltip-value">
                                    {poolData?.toShiftId === swapShiftDetails?.toShiftId
                                        ? swapShiftDetails?.toShiftDetails?.toRole
                                        : swapShiftDetails?.fromShiftDetails?.fromRole
                                    }
                                </div>
                            </div>
                            <div className="tooltip-row">
                                <div className="tooltip-label">
                                    {/* <JTranslation typeCase="capitalize" text={
                                        poolData?.toShiftId === swapShiftDetails?.toShiftId
                                            ? swapShiftDetails?.toShiftDetails?.shiftName
                                            : swapShiftDetails?.fromShiftDetails?.shiftName
                                    } /> */}
                                    {/* {
                                        poolData?.toShiftId === swapShiftDetails?.toShiftId
                                            ? swapShiftDetails?.toShiftDetails?.scheduleDate
                                                ? dayjs(swapShiftDetails?.toShiftDetails?.scheduleDate).format('MMM D, YYYY')
                                                : '-'
                                            : swapShiftDetails?.fromShiftDetails?.scheduleDate
                                                ? dayjs(swapShiftDetails?.fromShiftDetails?.scheduleDate).format('MMM D, YYYY')
                                                : '-'
                                    } */}
                                    {/* TODO: change, when swap details have scheduled date */}
                                    Time
                                </div>
                                <div className="tooltip-separator">:</div>
                                <div className="tooltip-value">
                                    {
                                        poolData?.toShiftId === swapShiftDetails?.toShiftId
                                            ? swapShiftDetails?.toShiftDetails?.toStartTime
                                            : swapShiftDetails?.fromShiftDetails?.fromStartTime
                                    } - {
                                        poolData?.toShiftId === swapShiftDetails?.toShiftId
                                            ? swapShiftDetails?.toShiftDetails?.toEndTime
                                            : swapShiftDetails?.fromShiftDetails?.fromEndTime
                                    }
                                </div>
                            </div>
                        </>
                    )}
                    <span className='mt-2'>
                        {statusTemplate(swapShiftDetails?.actionStatus, managePermission)}
                    </span>
                </small>
            </div>
        )
    }

    const statusTemplate = (currentStatus: SwapRequestStatus | '', isAdmin: boolean) => {
        let badgeText: string = ''
        let badgeSeverity: "success" | "info" | "warning" | "danger" | null = null
        switch (currentStatus) {
            case 'PENDING':
                badgeText = 'Pending'
                badgeSeverity = 'warning'
                break;
            case 'CANCELLED':
                badgeText = 'Cancelled'
                badgeSeverity = 'info'
                break;
            case 'DELETED':
                badgeText = 'Deleted'
                badgeSeverity = 'info'
                break;
            case 'STAFF_APPROVED':
                badgeText = 'Approval Pending'
                badgeSeverity = 'warning'
                if (isAdmin) {
                    badgeText = 'Staff approved'
                }
                break;
            case 'STAFF_REJECTED':
                badgeText = 'Staff rejected'
                badgeSeverity = 'danger'
                break;
            case 'MANAGER_APPROVED':
                badgeText = 'Approved'
                badgeSeverity = 'success'
                break;
            case 'MANAGER_REJECTED':
                badgeText = 'Manager rejected'
                badgeSeverity = 'danger'
                break;
            default:
                return <></>
        }
        return (
            <Badge
                className="custom-badge"
                value={<JTranslation typeCase="pascal" text={badgeText} />}
                severity={badgeSeverity}>
            </Badge>
        )
    }

    useEffect(() => {
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);

    return (
        <div className="custom-tooltip" onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
            {children}
            <OverlayPanel 
                ref={overlayRef}
                onShow={() => {
                    const currentElement = overlayRef?.current?.getElement();
                    if(currentElement) {
                        currentElement.style.zIndex = '1042'
                    }
                }}
            >
                {isVisible && (
                    <div className="tooltip-content">
                        <p>
                            <strong>{<JTranslation text={capitalize(staffName ?? '')} />}</strong>
                        </p>
                        {Array.isArray(content)
                            ? content.map((shift, index) => {
                                const { assignedShifts } = shift
                                const { startTime, endTime, isBd, isClose, role } = assignedShifts
                                return (
                                    <React.Fragment key={index}>
                                        <div className="tooltip-container">
                                            <div className="tooltip-row">
                                                <div className="tooltip-label"><JTranslation typeCase="capitalize" text={'Role'} /></div>
                                                <div className="tooltip-separator">:</div>
                                                <div className="tooltip-value">{<JTranslation text={role} />}</div>
                                            </div>
                                            <div className="tooltip-row">
                                                <div className="tooltip-label"><JTranslation typeCase="capitalize" text={'Time'} /></div>
                                                <div className="tooltip-separator">:</div>
                                                <div className="tooltip-value">
                                                    {isClose ? `${startTime} - Close` : ""}
                                                    {isBd ? `${startTime} - V` : ""}
                                                    {!isBd && !isClose ? `${startTime} - ${endTime}` : ''}
                                                </div>
                                            </div>
                                            {swapTemplate(shift)}
                                        </div>
                                        {index !== content.length - 1 && <hr className="tooltip-divider" />}
                                    </React.Fragment>
                                )
                            })
                            : content}
                    </div>
                )}
            </OverlayPanel>
        </div>
    );
};

export default CustomTooltip;
