import { Field, FieldProps, ErrorMessage, FieldArrayRenderProps } from "formik";
import { JTranslation } from "../../helpers/jTranslate";
import { parseTimeForTimePicker } from "../../helpers/newsAndEventsHelper";
import { END_TIME, START_TIME } from "../../constants/strings";
import MlxTimePicker from "../../components/mlx_time_picker/MlxTimePicker";

type Props = {
    day: string;
    hourIndex: number;
    businessHoursHelper: FieldArrayRenderProps;
};

const BusinessHours = ({ day, businessHoursHelper, hourIndex }: Props) => {

    return (
        <>
            <div className="flex-grow-1 me-3">
                <Field name={`${day}.workingHours.${hourIndex}.startTime`}>
                    {({ field, form }: FieldProps) => (
                        <MlxTimePicker
							className="form-control"
                            placeholder={START_TIME}
                            // Bind the value and onChange handler
                            data-testid="start-time-picker"
                            value={parseTimeForTimePicker({ formattedTime: field.value })}
                            onChange={(value) => form.setFieldValue(field.name, value)}
                            // style={
                            //     errors.startTime && touched.startTime
                            //         ? { border: '1px solid red' }
                            //         : undefined
                            // }
                        />
                    )}
                </Field>
                <ErrorMessage
                    className="formik-error"
                    name={`${day}.workingHours.${hourIndex}.startTime`}
                    component="div"
                    render={(error) => (
                        <div className="formik-error">
                            <JTranslation typeCase="none" text={error} />
                        </div>
                    )}
                />
            </div>

            <div className="flex-grow-1 me-3">
                <Field name={`${day}.workingHours.${hourIndex}.endTime`}>
                    {({ field, form }: FieldProps) => (
                        <MlxTimePicker
							className="form-control"
                            placeholder={END_TIME}
                            // Bind the value and onChange handler
                            data-testid="end-time-picker"
                            value={parseTimeForTimePicker({ formattedTime: field.value })}
                            onChange={(value) => form.setFieldValue(field.name, value)}
                            // style={
                            //     errors.endTime && touched.endTime
                            //         ? { border: '1px solid red' }
                            //         : undefined
                            // }
                        />
                    )}
                </Field>
                <ErrorMessage
                    className="formik-error"
                    name={`${day}.workingHours.${hourIndex}.endTime`}
                    component="div"
                    render={(error) => (
                        <div className="formik-error">
                            <JTranslation typeCase="none" text={error} />
                        </div>
                    )}
                />
            </div>
            <div className="flex-grow-0">
                <span
                    className={hourIndex > 0 ? "delete " : "delete visibility-hidden"}
                    onClick={() => businessHoursHelper.remove(hourIndex)}
                >
                    <i className="ri ri-delete-bin-line"></i>
                </span>

            </div>

        </>

    );
};

export default BusinessHours;
