import { useState, useEffect } from 'react'
import { AxiosResponse } from 'axios'
import { Badge } from 'primereact/badge'
import { Card } from 'primereact/card'
import { convertUTCtoLocalByOffset, getHref, getStaffId } from '../../helpers/utils'
import { getIconName, getNavigateUrl, getUnreadNotificationListView, markNotificationAsRead } from '../../helpers/notificationHelper'
import { HEADER_RENDERED, LOGIN_STATUS, UNREAD_NOTIFICATIONS_LIST } from '../../constants/queryKeys'
import { JTranslation } from '../../helpers/jTranslate'
import { NotificationObject } from '../../constants/staticTypes'
import { useNavigate } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'
import { useTrackEvent } from '../../hooks/useTrackEvent'
import noNotificationImage from '../../assets/images/no-notifications.png'
import useQueryHook from '../../hooks/useQueryHook'


function NotificationCard() {
	const id = getStaffId()
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const { trackEvent } = useTrackEvent()
	const unreadNotificationListData = queryClient.getQueryData(UNREAD_NOTIFICATIONS_LIST);
	const unreadNotificationList = (unreadNotificationListData as any)?.data?.data?.list?.slice(0,5);

	const [unreadBadge, setUnreadBadge] = useState(unreadNotificationList?.length ?? 0)
	const [notificationList, setNotificationList] = useState<NotificationObject[]>(unreadNotificationList ?? [])
	const [isLoading, setIsLoading] = useState(false)

	// login status value
	const loginStatus = queryClient.getQueryData(LOGIN_STATUS) as {
		isLoggedIn: boolean
	}

	const unreadNotifications = useQueryHook(
		UNREAD_NOTIFICATIONS_LIST,
		() => getUnreadNotificationListView(queryClient, id),
		(res: AxiosResponse<any, any>) => {
			const data: NotificationObject[] = res.data.data.list
			setUnreadBadge(data?.length)
			setNotificationList(data?.slice(0, 5))
		},
		() => { },
		true,
		false,
		60 * 1000
	)

	useEffect(() => {
		const initialLoad = queryClient.getQueryData(HEADER_RENDERED) as boolean
		if (loginStatus?.isLoggedIn && !initialLoad) {
			queryClient.setQueryData(HEADER_RENDERED, true)
			unreadNotifications.refetch()
		}
	}, [])

	// mark notification as read and navigate
	const markAsRead = (notification: NotificationObject) => {
		setIsLoading(true)
		const params = {
			staffId: id,
			notificationIds: [notification.id],
			readStatus: true,
		}
		markNotificationAsRead(queryClient, params)
			.then(() => {
				queryClient.refetchQueries([UNREAD_NOTIFICATIONS_LIST])
			})
			.catch((response) => {
				console.error(`failed to mark as read`, response)
			})
			.finally(() => {
				setIsLoading(false)
				navigate(getNavigateUrl(notification))
			})
	}

	return (
		<Card
			className="dashboard-notification-card card h-100 custom-shadow border-radius-05 border-0"
			title={
				<>
					<div className='d-flex align-items-center'>
						<JTranslation typeCase="pascal" text={"Notifications"} />
						{(isLoading || unreadNotifications.isFetching) ? (
							<span className="ms-2">
								<i className="pi pi-spin pi-spinner"></i>
							</span>
						) : (
							unreadBadge > 0 && (
								<Badge className="ms-2" value={unreadBadge >= 10 ? '10+' : unreadBadge} severity="warning"></Badge>

							)
						)}
					</div>
					<button className="btn btn-custom-primary dashboard-btn" onClick={() => {
						// Google Analytics
						trackEvent({
							eventName: "button_click_dashboard_notifications_view_all",
							value: {
								page_location: getHref(),

							},
						});

						navigate('/my-notifications');
					}}><JTranslation typeCase="pascal" text={'View All'} /></button>
				</>
			}
		>

			<div className='dashboard-widget-height notification-panel h-100'>
				{notificationList.length > 0 && (
					<div className="d-flex flex-column h-100">
						<div className="flex-grow-1 overflow-auto notification-wrapper">
							<div className="col-lg-12 px-1">
								{notificationList.map((notification, index) => (
									<div
										key={index}
										className={`mb-2 notification-list p-3 ${notification?.isRead ? 'read' : 'unread'
											}`}
										role="button"
										onClick={() => {
											markAsRead(notification);
										}}
									>
										<div className="notification-type">
											<i className={getIconName(notification?.topic)}></i>
										</div>
										<div className="ps-3 notification-message">
											<p className="m-0 mb-1">
												<span className="f-600">
													{notification?.notification?.title}
												</span>
												<small className="ps-3 text-muted">
													{convertUTCtoLocalByOffset(notification?.timestamp, {
														humanizeFormat: true,
													})}
												</small>
											</p>
											<p className="m-0 pt-0">
												{notification?.notification?.body?.slice(0, 60) + '...'}
											</p>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				)}

				{notificationList.length === 0 && unreadNotifications.isFetched && !unreadNotifications.isFetching && (
					<div className="h-100 d-flex flex-column align-items-center justify-content-center">
						<div className="flex-grow-1 d-flex align-items-center justify-content-center p-3">
							<img
								className="img-fluid"
								src={noNotificationImage}
								alt="no-notifications"
							/>
						</div>
					</div>
				)}
			</div>

		</Card>
	)
}

export default NotificationCard