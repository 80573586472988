import { useContext, useEffect, useRef, useState } from "react";
import { CANCEL, PWA_INSTALL, PWA_INSTALL_MSG } from "../../constants/strings";
import { PwaCtx } from "../../context/PwaCtxProvider";
import { jTranslationText, TranslationContext } from "../../helpers/jTranslate";
import PWAInstall from '@khmyznikov/pwa-install/react-legacy';
import { useWindowSize } from "react-use";
import { checkIfIos, getBrowserType } from "../../helpers/utils";

function PwaPopup() {
    const { width: currentWidth } = useWindowSize();
    const pwaInstallRef = useRef<any>(null);
    const { pwaPromptEvent, showInstallPromotion } = useContext(PwaCtx)
    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext;

    const [translationText, setTranslatedText] = useState<{
        install: string,
        install_message: string,
        install_button: string,
        cancel_button: string,
    }>({
        install: PWA_INSTALL,
        install_message: PWA_INSTALL_MSG,
        install_button: 'Install',
        cancel_button: CANCEL
    });

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: PWA_INSTALL, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: PWA_INSTALL_MSG, typeCase: 'capitalize', translationContext }),
                    jTranslationText({ text: 'Install', typeCase: 'capitalize', translationContext }),
                    jTranslationText({ text: CANCEL, typeCase: 'capitalize', translationContext }),
                ])

                setTranslatedText({
                    install: translations[0] ?? PWA_INSTALL,
                    install_message: translations[1] ?? PWA_INSTALL_MSG,
                    install_button: translations[2] ?? 'Install',
                    cancel_button: translations[3] ?? CANCEL,
                })
            } catch {
                setTranslatedText({
                    install: PWA_INSTALL,
                    install_message: PWA_INSTALL_MSG,
                    install_button: 'Install',
                    cancel_button: CANCEL,
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    // update pwa popup style
    useEffect(() => {
        if(!showInstallPromotion) return;

        if(pwaInstallRef?.current) {
            pwaInstallRef?.current?.showDialog();

            const pwaElement = document.getElementsByTagName('pwa-install')[0];
            const pwaShadowElements = pwaElement?.shadowRoot?.childNodes;
            for (const node of pwaShadowElements ?? []) {
                if (node instanceof Element && node.id === 'pwa-install-element') {
                    const installDialog = node.querySelector('.install-dialog.chrome');
                    if (installDialog instanceof HTMLElement) {
                        installDialog.style.right = '25px';
                    }

                    const isIos = checkIfIos();
                    const isSafari = getBrowserType() === 'Safari';
                    console.log({isIos, isSafari, stepsElement: node?.querySelectorAll('.step-text') });

                    if(isIos && !isSafari) {
                        const stepsElement = node?.querySelectorAll('.step-text');
                        if(stepsElement[0]?.textContent !== undefined) {
                            const step1Element = document.createElement('span');
                            step1Element.textContent = '1) Open app in browser';
                            stepsElement[0]?.replaceWith(step1Element);
                        }
                        if(stepsElement[1]?.textContent !== undefined) {
                            const step2Element = document.createElement('span');
                            step2Element.textContent = '2) Press Share in Navigation bar';
                            stepsElement[1]?.replaceWith(step2Element);
                        }
                        if(stepsElement[2]?.textContent !== undefined) {
                            const step3Element = document.createElement('span');
                            step3Element.textContent = '3) Press Add to Home Screen';
                            stepsElement[2]?.replaceWith(step3Element);
                        }
                    }
                }
            }
        }
        
    }, [pwaInstallRef?.current, showInstallPromotion])

    return (
        <PWAInstall
            className="pwa-popup"
            ref={pwaInstallRef}
            externalPromptEvent={pwaPromptEvent as BeforeInstallPromptEvent}
            installDescription={translationText.install_message}
            description={currentWidth < 768 ? translationText.install_message : ''}
            disableScreenshots={true}
        ></PWAInstall>
    )
}

export default PwaPopup;