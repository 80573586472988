import { Dispatch, SetStateAction, PropsWithChildren, useState, useMemo, createContext, useEffect, MouseEvent } from 'react';
import { useLocation } from 'react-router-dom';
import { routes } from '../constants/routes';
import { checkIfIos } from '../helpers/utils';

type DisplayMode = 'twa' | 'browser' | 'standalone' | 'minimal-ui' | 'fullscreen' | 'window-controls-overlay' | 'unknown';

interface PwaContextValue {
    appInstalled: boolean;
    setAppInstalled: Dispatch<SetStateAction<boolean>>;
    deferredPromptEvent: MouseEvent | null;
    setDeferredPromptEvent: Dispatch<SetStateAction<MouseEvent | null>>;
    showInstallPromotion: boolean;
    setShowInstallPromotion: Dispatch<SetStateAction<boolean>>;
    pwaPromptEvent: Event | null;
}

export const PwaCtx = createContext<PwaContextValue>({
    appInstalled: false,
    setAppInstalled: () => { },
    deferredPromptEvent: null,
    setDeferredPromptEvent: () => { },
    showInstallPromotion: false,
    setShowInstallPromotion: () => { },
    pwaPromptEvent: null
});

export const PwaCtxProvider = ({ children, pwaPromptEvent }: PropsWithChildren & { pwaPromptEvent: Event | null }) => {
    const location = useLocation();

    const [appInstalled, setAppInstalled] = useState(false);
    const [showInstallPromotion, setShowInstallPromotion] = useState(false);
    const [deferredPromptEvent, setDeferredPromptEvent] = useState<MouseEvent | null>(null);

    const getPWADisplayMode = (): DisplayMode => {
        if (document.referrer.startsWith('android-app://'))
            return 'twa';
        if (window.matchMedia('(display-mode: browser)').matches)
            return 'browser';
        if (window.matchMedia('(display-mode: standalone)').matches || (navigator as any)?.standalone)
            return 'standalone';
        if (window.matchMedia('(display-mode: minimal-ui)').matches)
            return 'minimal-ui';
        if (window.matchMedia('(display-mode: fullscreen)').matches)
            return 'fullscreen';
        if (window.matchMedia('(display-mode: window-controls-overlay)').matches)
            return 'window-controls-overlay';

        return 'unknown';
    }

    useEffect(() => {
        const handlePwaInstallPrompt = () => {
            if (!pwaPromptEvent || !deferredPromptEvent) return;

            setShowInstallPromotion(false);
            (pwaPromptEvent as any)?.prompt();
            (pwaPromptEvent as any)?.userChoice.then((choiceResult: any) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
                setDeferredPromptEvent(null);
            });
        }

        handlePwaInstallPrompt();
    }, [deferredPromptEvent, pwaPromptEvent])

    useEffect(() => {
        const displayMode: DisplayMode = getPWADisplayMode();
        console.log(displayMode, appInstalled)
        if (
            location?.pathname &&
            [routes.login, routes.dashboard].includes(location?.pathname) &&            
            !appInstalled &&
            displayMode !== 'standalone'
        ) {
            const isIos = checkIfIos();
            if(isIos || (!isIos && pwaPromptEvent)) {
                setShowInstallPromotion(true);
            }
        } else {
            setShowInstallPromotion(false);
        }
    }, [location?.pathname, appInstalled, pwaPromptEvent]);

    useEffect(() => {
        const handleAppInstalled = () => {
            setAppInstalled(true);
            setShowInstallPromotion(false);
        }

        window.addEventListener('appinstalled', handleAppInstalled);
        return () => {
            window.removeEventListener('appinstalled', handleAppInstalled);
        };
    }, []);

    return (
        <PwaCtx.Provider
            value={useMemo(() => {
                return {
                    appInstalled,
                    setAppInstalled,
                    deferredPromptEvent,
                    setDeferredPromptEvent,
                    showInstallPromotion,
                    setShowInstallPromotion,
                    pwaPromptEvent
                };
            }, [
                appInstalled,
                deferredPromptEvent,
                showInstallPromotion,
                pwaPromptEvent
            ])}
        >
            {children}
        </PwaCtx.Provider>
    );
};