import { Dispatch, MutableRefObject, SetStateAction } from "react";
import { BusinessDefaultHours, BusinessHoursType } from "../../constants/staticTypes";
import { businessHoursSchema, convertBusinessHoursToApiData } from "../../helpers/generalSettingsHelper";
import { Button } from "primereact/button";
import { days } from "../../helpers/workTimeScheduleHelper";
import { Formik, Form, Field, FieldProps, FieldArray, FieldArrayRenderProps, FormikErrors } from "formik";
import { InputSwitch } from "primereact/inputswitch";
import { JTranslation } from "../../helpers/jTranslate";
import BusinessHours from "./BusinessHours";

type Props = {
    businessHoursFormData: BusinessHoursType;
    setBusinessHoursFormData: Dispatch<SetStateAction<BusinessHoursType>>;
    setBusinessDefaultHours: Dispatch<SetStateAction<BusinessDefaultHours[]>>;
    submitFnRef: MutableRefObject<any>;
    setBusinessHoursErrors: Dispatch<SetStateAction<FormikErrors<BusinessHoursType>>>;
};

const BusinessDays = ({
    businessHoursFormData,
    setBusinessHoursFormData,
    setBusinessDefaultHours,
    submitFnRef,
    setBusinessHoursErrors,
}: Props) => {

    const handleSubmit = (values: BusinessHoursType) => { };

    const validate = (values: BusinessHoursType) => {
        setBusinessHoursFormData(values);
        const afterConversion = convertBusinessHoursToApiData(values)
        setBusinessDefaultHours(afterConversion)
    };

    const addBusinessHours = (businessHoursHelper: FieldArrayRenderProps) => {
        businessHoursHelper.push({ startTime: "", endTime: "", note: "" });
    }

    return (
        <div>
            <Formik
                initialValues={businessHoursFormData}
                enableReinitialize={true}
                validationSchema={() => businessHoursSchema}
                validateOnChange={true}
                validateOnMount={true}
                validate={validate}
                onSubmit={handleSubmit}
            >
                {({ values, errors, touched, submitForm }) => {
                    submitFnRef.current = submitForm;
                    setBusinessHoursErrors(errors)
                    return (

                        <Form>
                            {days.map((day) => (
                                <div className="col-sm-12 col-md-9 col-lg-7 mb-5" key={day}>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <label className="form-label d-flex" >
                                                <Field name={`${day}.isOpen`}>
                                                    {({ field, form }: FieldProps) => (
                                                        <InputSwitch
                                                            className="me-2"
                                                            checked={field.value}
                                                            onChange={(e) => {
                                                                form.setFieldValue(field.name, e.target.value);
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                                <JTranslation typeCase="pascal" text={day} />
                                            </label>
                                        </div>

                                        {
                                            !values[day].isOpen
                                                ? (<div
                                                    className="festival-page-selection col-sm-8 disabled"
                                                >
                                                    <div className='d-flex'>
                                                        <div className='flex-grow-0'>
                                                            <Button icon='pi pi-moon' />
                                                        </div>
                                                        <div
                                                            className='flex-grow-1 mr-2 ps-2 d-flex align-items-center justify-content-between text-nowrap overflow-hidden'
                                                        >
                                                            <span className='menu-text'>
                                                                <JTranslation
                                                                    typeCase='pascal'
                                                                    text={'Closed'}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>)
                                                : ""
                                        }

                                        <div className="col-sm-8" style={{ display: values[day].isOpen ? 'block' : 'none' }}>
                                            {values[day].workingHours.map((_hour, hourIndex) => {
                                                return (
                                                    <FieldArray
                                                        name={`${day}.workingHours`}
                                                        key={`${day}-${hourIndex}`}
                                                        render={(businessHoursHelper) => {
                                                            return (
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col-12 d-flex align-items-center">
                                                                            <BusinessHours
                                                                                day={day}
                                                                                hourIndex={hourIndex}
                                                                                businessHoursHelper={businessHoursHelper}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-12 d-flex justify-content-end mt-2">
                                                                            {hourIndex === values[day].workingHours.length - 1 ? (
                                                                                <small
                                                                                    className="add-time"
                                                                                    onClick={() =>
                                                                                        addBusinessHours(businessHoursHelper)
                                                                                    }
                                                                                >
                                                                                    <i className="ri-add-fill"></i>
                                                                                    <JTranslation typeCase="pascal" text={'Add Time'} />
                                                                                </small>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            );
                                                        }}
                                                    />
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Form>
                    )
                }
                }
            </Formik>
        </div>
    );
};

export default BusinessDays;
