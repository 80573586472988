import { Badge } from 'primereact/badge'
import { Button } from 'primereact/button'
import { OverlayPanel } from 'primereact/overlaypanel'
import { useContext, useEffect, useRef, useState } from 'react'
import { useScrolling } from 'react-use'
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate'
import {
	getIconName,
	getNavigateUrl,
	getUnreadNotificationListView,
	markAllNotificationsAsRead,
	markNotificationAsRead,
} from '../../helpers/notificationHelper'
import { NotificationObject } from '../../constants/staticTypes'
import { convertUTCtoLocalByOffset, getStaffId } from '../../helpers/utils'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../constants/routes'
import noNotificationImage from '../../assets/images/no-notifications.png'
import useQueryHook from '../../hooks/useQueryHook'
import { HEADER_RENDERED, LOGIN_STATUS, UNREAD_NOTIFICATIONS_LIST } from '../../constants/queryKeys'
import { AxiosResponse } from 'axios'
import { useQueryClient } from 'react-query'

const NotificationPanelPopup = () => {
	const id = getStaffId()
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const notificationRef = useRef<any>(null)
	const windowScrollElement = document.getElementById('GuestMain') as HTMLElement
	const scrolling = useScrolling({ current: windowScrollElement })
	const translationContext = useContext(TranslationContext)
	const { targetLanguage } = translationContext
	const unreadNotificationListData = queryClient.getQueryData(UNREAD_NOTIFICATIONS_LIST);
	const unreadNotificationList = (unreadNotificationListData as any)?.data?.data?.list?.slice(0,5);

	const [unreadBadge, setUnreadBadge] = useState(unreadNotificationList?.length ?? 0)
	const [notificationList, setNotificationList] = useState<NotificationObject[]>(unreadNotificationList ?? [])
	const [popupEvent, setPopupEventEvent] = useState(null)
	const [translationText, setTranslatedText] = useState<{
		markAllAsRead: string
		clearAll: string
		footerButton: string
	}>({
		markAllAsRead: 'Mark all as read',
		clearAll: 'Clear all',
		footerButton: 'Go to notification center',
	})

	// login status value
	const loginStatus = queryClient.getQueryData(LOGIN_STATUS) as {
		isLoggedIn: boolean
	}

	const unreadNotifications = useQueryHook(
		UNREAD_NOTIFICATIONS_LIST,
		() => getUnreadNotificationListView(queryClient, id),
		(res: AxiosResponse<any, any>) => {
			const data: NotificationObject[] = res.data.data.list
			setUnreadBadge(data?.length)
			setNotificationList(data?.slice(0, 5))
		},
		() => { },
		true,
		false,
		60 * 1000
	)

	// mark all as read
	const markAllAsRead = () => {
		markAllNotificationsAsRead(queryClient, id)
			.then(() => {
				unreadNotifications.refetch()
			})
			.catch((response) => {
				console.error(`failed to mark as read`, response)
			})
	}

	// mark notification as read and navigate
	const markAsRead = (notification: NotificationObject) => {
		const params = {
			staffId: id,
			notificationIds: [notification.id],
			readStatus: true,
		}
		markNotificationAsRead(queryClient, params)
			.then(() => {
				unreadNotifications.refetch()
			})
			.catch((response) => {
				console.error(`failed to mark as read`, response)
			})
		navigate(getNavigateUrl(notification))
	}

	// notification center
	const NotificationCenter = () => {
		return (
			<div className="w-100 flex-grow-0">
				<Button
					className="w-100 notification-button-footer"
					type="button"
					label={translationText.footerButton}
					icon={
						unreadNotifications.isLoading ||
							unreadNotifications.isFetching ||
							unreadNotifications.isRefetching
							? 'pi pi-spin pi-spinner'
							: 'ri-arrow-right-line'
					}
					iconPos="right"
					onClick={() => {
						navigate(routes.my_notifications)
					}}
					disabled={
						unreadNotifications.isLoading ||
						unreadNotifications.isFetching ||
						unreadNotifications.isRefetching
					}
				></Button>
			</div>
		)
	}

	// hide popup menu on scroll
	useEffect(() => {
		if (scrolling && notificationRef.current && popupEvent) {
			notificationRef.current.hide(popupEvent)
			setPopupEventEvent(null)
		}
	}, [scrolling])

	// Translate on load and language switch
	useEffect(() => {
		const fetchTranslation = async () => {
			try {
				const translations = await Promise.all([
					jTranslationText({ text: 'Mark all as read', typeCase: 'capitalize', translationContext }),
					jTranslationText({ text: 'Clear all', typeCase: 'capitalize', translationContext }),
					jTranslationText({ text: 'Go to notification center', typeCase: 'capitalize', translationContext }),
				])

				setTranslatedText({
					markAllAsRead: translations[0] ?? 'Mark all as read',
					clearAll: translations[1] ?? 'Clear all',
					footerButton: translations[2] ?? 'Go to notification center',
				})
			} catch {
				setTranslatedText({
					markAllAsRead: 'Mark all as read',
					clearAll: 'Clear all',
					footerButton: 'Go to notification center',
				})
			}
		}
		fetchTranslation()
	}, [targetLanguage])

	useEffect(() => {
		const initialLoad = queryClient.getQueryData(HEADER_RENDERED) as boolean
		if (loginStatus?.isLoggedIn && !initialLoad) {
			queryClient.setQueryData(HEADER_RENDERED, true)
			unreadNotifications.refetch()
		}
	}, [])

	return (
		<div className="notification-icon-panel icon-only-link">
			{unreadBadge > 0 && <Badge severity="danger" className="mr-2 notification-icon-badge"></Badge>}
			<i onClick={(event) => {
					notificationRef.current?.toggle(event)
					setPopupEventEvent(event as any)
				}} className="ri-notification-3-line" aria-controls="popup_menu_left" aria-haspopup></i>
			{/* <Button
				icon="pi pi-bell"
				rounded
				outlined
				className="mr-2 notification-icon-button"
				onClick={(event) => {
					notificationRef.current?.toggle(event)
					setPopupEventEvent(event as any)
				}}
				aria-controls="popup_menu_left"
				aria-haspopup
			></Button>  */}
			<OverlayPanel ref={notificationRef} className="notification-popup-panel">
				<div className="notification-panel h-100">
					{notificationList.length > 0 ? (
						<div className="d-flex flex-column h-100">
							<div className="flex-grow-0 mb-2">
								<p className="mt-2 fw-bold">
									<JTranslation
										typeCase="capitalize"
										text={`You have ${unreadBadge} new notifications`}
									/>
								</p>
								<div className="d-flex justify-content-end align-items-center">
									<Button
										size="small"
										className="notification-button-read"
										type="button"
										label={translationText.markAllAsRead}
										icon="ri-check-double-fill"
										onClick={() => markAllAsRead()}
									></Button>
								</div>
							</div>
							<div className="flex-grow-1 overflow-auto notification-wrapper">
								<div className="col-lg-12 px-1">
									{notificationList.map((notification, index) => (
										<div
											key={index}
											className={`mb-2 notification-list ${notification?.isRead ? 'read' : 'unread'
												}`}
											role="button"
											onClick={() => {
												markAsRead(notification)
											}}
										>
											<div className="notification-type">
												<i className={getIconName(notification?.topic)}></i>
											</div>
											<div className="ps-3 notification-message">
												<p className="m-0 mb-1">
													<span className="f-600">
														{notification?.notification?.title}
													</span>
													<small className="ps-3 text-muted">
														{convertUTCtoLocalByOffset(notification?.timestamp, {
															humanizeFormat: true,
														})}
													</small>
												</p>
												<p className="m-0 pt-0">
													{notification?.notification?.body?.slice(0, 60) + '...'}
												</p>
											</div>
										</div>
									))}
								</div>
							</div>
							<NotificationCenter />
						</div>
					) : (
						<div className="h-100 d-flex flex-column align-items-center justify-content-center">
							<div className="flex-grow-1 d-flex align-items-center justify-content-center p-3">
								<img className="img-fluid" src={noNotificationImage} alt="no-notifications" />
							</div>
							<NotificationCenter />
						</div>
					)}
				</div>
			</OverlayPanel>
		</div>
	)
}

export default NotificationPanelPopup
