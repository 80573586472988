import { createContext, Dispatch, PropsWithChildren, SetStateAction, useState, useMemo, useContext } from 'react';
import { initialValuesForTimeOff } from '../helpers/timeOffHelper';
import { initialValuesForHoliday } from '../helpers/holidayHelper';
import { TimeOffRequestFormType, HolidayFormDataType, AvailabilityFormData, ShiftAssignment } from '../constants/staticTypes';
import { initialValuesForAvailability } from '../helpers/availabilityHelper';
import { initialDataShiftAssignment } from '../helpers/workTimeScheduleHelper';

// SETTER TYPE DECLARATIONS
type SetTimeOffData = Dispatch<SetStateAction<TimeOffRequestFormType>>;
type SetHolidayData = Dispatch<SetStateAction<HolidayFormDataType>>;
type SetAvailabilityData = Dispatch<SetStateAction<AvailabilityFormData>>;
type SetSavedStaffData = Dispatch<SetStateAction<any>>;
type SetCurrentStaffIndex = Dispatch<SetStateAction<number>>;
type SetShiftFormHistory = Dispatch<SetStateAction<ShiftAssignment>>;

// UPDATE FUNCTION DECLARATIONS
const UpdateTimeOffData: SetTimeOffData = () => initialValuesForTimeOff;
const UpdateHolidayData: SetHolidayData = () => initialValuesForHoliday;
const UpdateAvailabilityData: SetAvailabilityData = () => initialValuesForAvailability;
const UpdateSavedStaffData: SetSavedStaffData = () => undefined;
const UpdateCurrentStaffIndex: SetCurrentStaffIndex = () => undefined;
const UpdateShiftFormHistory: SetShiftFormHistory = () => initialDataShiftAssignment;

// CONTEXT VARIABLES
export const ScheduleManagementCtx = createContext({
	timeOffData: initialValuesForTimeOff,
	setTimeOffData: UpdateTimeOffData,
	holidayData: initialValuesForHoliday,
	setHolidayData: UpdateHolidayData,
	availabilityData: initialValuesForAvailability,
	setAvailabilityData: UpdateAvailabilityData,
	savedStaffData: undefined,
	setSavedStaffData: UpdateSavedStaffData,
	currentStaffIndex: 0, 
	setCurrentStaffIndex: UpdateCurrentStaffIndex,
	shiftFormHistory: initialDataShiftAssignment,
	setShiftFormHistory: UpdateShiftFormHistory
});

export function ScheduleManagementCtxProvider({ children }: PropsWithChildren) {
	// STATE VARIABLES
	const [timeOffData, setTimeOffData] = useState(initialValuesForTimeOff);
	const [holidayData, setHolidayData] = useState(initialValuesForHoliday);
	const [availabilityData, setAvailabilityData] = useState(initialValuesForAvailability);
	const [savedStaffData, setSavedStaffData] = useState<any>();
	const [currentStaffIndex, setCurrentStaffIndex] = useState<number>(0);
	const [shiftFormHistory, setShiftFormHistory] = useState<ShiftAssignment>(initialDataShiftAssignment);

	// CONTEXT PROVIDER
	return (
		<ScheduleManagementCtx.Provider
			value={useMemo(
				() => ({
					timeOffData, setTimeOffData,
					holidayData, setHolidayData,
					availabilityData, setAvailabilityData,
					savedStaffData, setSavedStaffData,
					currentStaffIndex, setCurrentStaffIndex,
					shiftFormHistory, setShiftFormHistory
				}),
				[timeOffData, holidayData, availabilityData, savedStaffData, currentStaffIndex, shiftFormHistory]
			)}
		>
			{children}
		</ScheduleManagementCtx.Provider>
	);
}
