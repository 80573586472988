// General
export const COPYRIGHT = `© ${new Date().getFullYear()} MicroLogX, Inc. All rights reserved.`

// Buttons
export const CLEAR_DRAFT = "Clear Draft";
export const BD = "Business Decline";
export const SHIFT = "Shift";
export const SELECT_DEPARTMENT = "Select Department";
export const SELECT_SHIFT = "Select Shift";
export const SELECT_ROLE = "Select Role";
export const FILTER_LOCATION = "Filter by Location";
export const CREATE_SHIFT = "Create Shift";
export const APPLY = "Apply";
export const SMALL = "Small";
export const MEDIUM = "Medium";
export const LARGE = "Large";
export const DOWNLOAD_QR_CODE = "Download QR Code";
export const GENERATE = "Generate";
export const PUBLISH = "Publish";
export const PUBLISHED = "Published";
export const DRAFT = "Draft";
export const SUBMIT = "Submit";
export const LOGIN_TEXT = "Login";
export const FORGOT_PASSWORD = "Forgot Password";
export const BACK_TO_LOGIN = "Back To Login";
export const BACK_TO_WELCOME = "Go to welcome page";
export const BACK_TO_FNB_MENU = "Go to main menu";
export const BACK = "Back";
export const EMAIL_TEXT = "Email";
export const MOBILE_TEXT = "Mobile";
export const PHONE_TEXT = "Phone";
export const SEND = "Send";
export const NEXT = "Next";
export const SAVE = "Save";
export const UPDATE = "Update";
export const CLEAR = "Clear";
export const CHECK = "Check";
export const CREATE = "Create";
export const CANCEL = "Cancel";
export const PROCEED = "Proceed";
export const ADD = "Add";
export const ADD_NEW = "Add New";
export const ADD_HISTORY = "Add History";
export const WINE_HISTORY = "History";
export const ADD_CATEGORY = "Add Category";
export const ADD_QUESTION = "Add Question";
export const CLOSE = "Close";
export const CONTINUE = "Continue";
export const PRIZE_LIST = "Prize List";
export const REDEEM = "Redeem";
export const CONFIRM = "Confirm";
export const DOWNLOAD = "Download";
export const QR_DOWNLOAD = "QR Code Download";
export const SET_PASSWORD = "Send Set Password Link";
export const RESET = "Reset";
export const FROM = "from";
export const TO = "to";
export const GENERATE_DESCRIPTION = "Auto Generate";
export const REGENERATE_DESCRIPTION = "Regenerate";
export const SAVE_AS_DRAFT = "Save as Draft";
export const MORE_INFO = "More Info";
export const CLEAR_PROMPT = "Clear Prompt";

// Labels / Placeholders
export const FOOD = 'Food';
export const BEVERAGE = 'Beverage';
export const CHOOSE_TYPE = 'Choose Type';
export const NO_TITLE = 'No title found';
export const NO_ROLES_ASSIGNED = 'No roles assigned';
export const MINIMUM_STAFF_COUNT = "Minimum Staff Count";
export const SHIFT_NAME = "Shift Name";
export const MAGIC_LINK_LABEL = "Send login link";
export const ADD_HOLIDAY = "Add Holiday";
export const DATE = "DATE";
export const ADD_NEW_HOLIDAY = "Add New Holiday";
export const EDIT_HOLIDAY = "Edit Holiday";
export const UPLOAD_WEB_LOG = "Web Logo (coming soon)";
export const UPLOAD_MOBILE_LOG = "Mobile Logo";
export const IMAGE_PROMPT_PLACEHOLDER = "Enter your prompt here to generate a food or beverage image using AI. Be as specific or as general as you like. For example:\n- \"A plate of spaghetti with a side of garlic bread\"\n- \"An iced latte with a heart design in the foam\"\n- \"A bowl of fruit salad containing strawberries, blueberries, and kiwi\"\n\nFeel free to include details about the presentation, angle, or any special features you'd like to see in the generated image.";
export const PROMPT_PLACEHOLDER = "Provide a brief description for the text you want AI to generate";
export const HOURS = "Hours";
export const APPROVE = "Approve";
export const REJECT_TIME_OFF = "Reject Request";
export const CANCEL_TIME_OFF = "Cancel Request";
export const DELETE_TIME_OFF = "Delete Request";
export const DELETE_HOLIDAY_TITLE = "Delete Holiday";
export const REJECTING_TIME_OFF_REQUEST = "Do you wish to proceed with rejecting the time off request?";
export const CANCEL_TIME_OFF_REQUEST = "Do you wish to proceed with cancelling the time off request?";
export const DELETE_TIME_OFF_REQUEST = "Do you wish to proceed with deleting the time off request?";
export const DELETE_HOLIDAY = "Do you wish to proceed with deleting the holiday?";
export const REJECT = "Reject";
export const DURATION = "Duration";
export const HOLIDAY_TYPE = "Holiday Type";
export const NOTE = "Notes";
export const REASON = "Reason";
export const SUBMIT_DATE = "Submit Date";
export const REQUEST_DATE = "Request Date";
export const FROM_DATE = "From Date";
export const TO_DATE = "To Date";
export const PROMPT = "Prompt";
export const IMAGE_SIZE = "Image Size";
export const RESPONSE_LENGTH = "Response Length";
export const SHORT = "Short";
export const LONG = "Large";
export const HEADING = "Heading";
export const AUTO_PUBLISH_DATE = "Auto Publish Date";
export const PUBLISH_DATE = "Publish Date";
export const EXPIRY_DATE = "Expiry Date";
export const NEWS = "News";
export const EVENTS = "Events";
export const START_DATE = "Start Date";
export const EVENT_DATE = "Event Start Date";
export const END_DATE = "Event End Date";
export const ADD_EXISTING = "Add Existing";
export const SELECTED = "Selected";
export const EXISTING_MENU_ITEMS = "Existing Menu Items";
export const ADD_EXISTING_MENU_ITEMS = "Add Existing Menu Items";
export const CUSTOM_MENU = "Custom Menu";
export const MANAGE_CUSTOM_MENU = "Manage Custom Menu";
export const NO_TAG_FOUND = "No Tag Found";
export const NO_CATEGORY_FOUND = "No Category Found";
export const ASSOCIATED_ITEM = "Associated Item";
export const ASSOCIATED_ITEMS = "Associated Items";
export const USERNAME_TEXT = "Username";
export const PASSWORD_TEXT = "Password";
export const NEW_PASSWORD_TEXT = "New Password";
export const CONFIRM_PASSWORD_TEXT = "Confirm Password";
export const AREA_OF_OPERATION = "Area of operation";
export const ROLE = "Role";
export const ROLE_NAME = "Role Name";
export const ROLE_DESC = "Role Description";
export const PERMISSION_NAME = "Permission Name";
export const PERMISSION_DESC = "Permission Description";
export const FULL_NAME = "Full Name";
export const FIRST_NAME = "First Name";
export const LAST_NAME = "Last Name";
export const DISPLAY_NAME = "Display Name";
export const SERVER_NAME = "Server Name";
export const ANNIVERSARY = "Anniversary";
export const BIRTHDAY = "Birthday";
export const ZIP_CODE = "Zip Code";
export const NO_OF_VISIT = "No of Visit";
export const NO_OF_SURVEY = "No of Survey";
export const SURVEY_DETAILS = "Survey Details";
export const VIEW_SURVEY = "View Survey";
export const SURVEY_ANSWERS = "Survey Answers";
export const DATE_OF_VISIT = "Date of Visit";
export const FIRST_VISIT = "First Visit";
export const LAST_VISIT = "Last Visit";
export const HIRE_DATE = "Hire Date";
export const POS_NUMBER = "POS #";
export const USERNAME = "Username";
export const UPLOAD_PHOTO = "Upload Photo";
export const UPLOAD_VIDEO = "Upload Video";
export const STATUS = "Status";
export const COUPON_CODE = "Voucher Code";
export const EXPIRY = "Expiry";
export const REDEEMED = "Redeemed";
export const PRIZE = "Prize";
export const PRIZE_DESCRIPTION = "Prize Description";
export const QR_CODE = "QR";
export const DOWNLOAD_ALL_QR_CODE = "Download All QR Codes";
export const MENU = "Menu";
export const CATEGORY = "Category";
export const ITEMS = "Items";
export const MENU_NAME = "Menu Name";
export const CATEGORY_NAME = "Category Name";
export const MENU_ITEM_NAME = "Menu Item Name";
export const ITEM_NAME = "Item Name";
export const ADD_PRICE = "Pricing";
export const SIZE = "Size";
export const NAME = "Name";
export const PRICE = "Price";
export const LABEL = "Label";
export const ADD_SUGGESTION_ITEMS = "Add Recommended Pairings";
export const ADDON_PRICE = "Add-On Pricing";
export const ADDITIONAL_INFO = "Additional Info";
export const TAG = "Tags";
export const LOCATION = "Location";
export const START_TIME = "Start Time";
export const END_TIME = "End Time";
export const EVENT_START_TIME = "Event Start Time";
export const EVENT_END_TIME = "Event End Time";
export const DESCRIPTION = "Description";
export const TITLE = "Title";
export const PAYMENT = "Payment";
export const TYPE = "Type";
export const ORIGIN = "Origin";
export const COLOR = "Color";
export const BOUQUET = "Bouquet";
export const TASTE = "Taste";
export const DENOMINATION = "Denomination";
export const ITEM_SUGGESTION = "Item Suggestion";
export const UPLOAD_PHOTO_MESSAGE_MENU = "*For better results, upload images larger than 600*400px (Width * Height).";
export const UPLOAD_PHOTO_MESSAGE = "For better results, upload images larger than 600*400px (Width * Height).";
export const UPLOAD_WELCOME_SCREEN_IMAGE = "Upload your welcome screen image here";
export const MARK_UNAVAILABLE = "Mark as unavailable";
export const SHOW_PRICE_AS_MARKET_PRICE = "Show Market Price";
export const MARKET_PRICE = "Market Price";
export const ITEM_NOT_AVAILABLE = "Item not available";
export const VIEW_WINE_HISTORY = "View History";
export const ADD_EXTRA = "Add Extra";
export const RECOMMENDATION_WITH = " Recommended Pairings with ";
export const CREATE_QUESTION_SET_PLACEHOLDER = "Type your question set name";
export const GAME_ENABLED_TEXT = "Include game after survey";
export const CREATE_QUESTION_SET = "Create Question Set";
export const QUESTION_SET_TITLE = "Question Set Title";
export const QUESTION_SET_NAME = "Question Set Name";
export const QUESTION = "Question";
export const QUESTION_SET_TEXT = "Question Set";
export const CREATED_DATE = "Created Date";
export const CREATED = "Created";
export const ADD_SURVEY_QUESTIONS = "Add Survey Questions";
export const ACTIVE = "Active";
export const INACTIVE = "Inactive";
export const FILL_SURVEY = "Fill the survey";
export const YOU_WILL_GET_EXCITING_OFFERS = "You will get exciting offers";
export const FILL_YOUR_PERSONAL_INFO = "Fill your personal information";
export const CATEGORY_TYPE = "Category Type";
export const ENABLE = "Enable";
export const ENABLED = "Enabled";
export const DISABLED = "Disabled";
export const MANDATORY = "Mandatory";
export const ANSWER_TYPE = "Answer Type";
export const ADD_ROW = "Add Row";
export const OPTION = "Option";
export const OPTIONS = "Options";
export const TYPE_ANSWER = "Type your answer";
export const LOADING = "Loading";
export const SELECT = "Select";
export const PLEASE_ADD_CATEGORY = "Please add a category.";
export const NOT_APPLICABLE = "Not Applicable";
export const YES = "Yes";
export const NO = "No";
export const OFFER = "Offer";
export const OFFERS = "Offers";
export const MIN_VISIT_COUNT = "Min Visit Count";
export const WEIGHTAGE = "Weight";
export const MAX_COUPON = "Max Vouchers";
export const FREQUENCY = "Frequency";
export const COUPON_VALIDITY = "Voucher Validity";
export const DAYS = "Days";
export const AVAILABLE_COUPON = "Available Vouchers";
export const RADIUS = "Radius";
export const MILES = "Miles";
export const TEXT = "Text";
export const NO_OFFER_TEXT = "No offer text";
export const COUNT = "Count";
export const BETTER_LUCK_NEXT_TIME = "Better luck next time";
export const ADD_NO_OFFER_TEXT = "Add No Offer Text";
export const OFFER_LIST = "Offer List";
export const CONGRATULATIONS = "Congratulations";
export const TRY_AGAIN_NEXT_TIME = "Try again next time";
export const DEFAULT = "Default";
export const DEFAULT_PRICE = "Display Price";
export const DEFAULT_PRICE_LABEL = "Display price will be the one displayed on the guest menu";
export const ACTIVE_DEFAULT_PRICE_WARNING = "Please note that you are removing the display price from this item. Are you sure you want to proceed?"
export const LAST_ACTIVE_DEFAULT_PRICE_WARNING = "Please note that you are removing the display price from this item. This will make the lowest price appear on the guest menu. Are you sure you want to proceed?"
export const DELETE_DEFAULT_PRICE_WARNING = "Please note that you are deleting the display price from this item. This will make the lowest price appear on the guest menu. Are you sure you want to proceed?"
export const DELETE_DISPLAY_PRICE_WARNING = "Please note that you are deleting the display price from this item. Are you sure you want to proceed?"
export const DEFAULT_PRICE_WARNING = "You are setting the selected price as the display price. From here onwards this price will be displayed on the guest menu. Are you sure you want to proceed?"
export const ACTIVATE = "Activate";
export const DEACTIVATE = "Deactivate";
export const EDIT = "Edit";
export const VIEW = "View";
export const SCHEDULE = "Schedule";
export const COPY_SCHEDULE = "Copy Schedule";
export const COPY_SCHEDULE_TO = "Copy Schedule To";
export const DELETE = "Delete";
export const MOVE = "Move to";
export const COPY = "Copy to";
export const DUPLICATE = "Duplicate";
export const FILE_UPLOAD_LIMIT_WARNING = "You can only upload a maximum of"
export const FILES = "files"
export const FILE_UPLOAD = "File upload:"
export const FILE_UPLOAD_INPROGRESS = "File upload in progress"
export const FILE_UPLOAD_COMPLETED = "File upload completed"
export const VERIFY_TYPE = "Verify Type"
export const VERIFY_DATA = "Verify Data"
export const INVOICES = "Invoices"
export const INVOICE_NUMBER = "Invoice #"
export const CLICK_TO_OPEN = "Click to open the document"
export const VENDOR = "Vendor"
export const ORDER = "Order #"
export const TOTAL = "Total"
export const LOCK_DND = "Click to enable drag and drop"
export const UNLOCK_DND = "Click to disable drag and drop"
export const SHOW_DISPLAY_PRICE = "Click to show display price on items"
export const HIDE_DISPLAY_PRICE = "Click to hide display price on items"
export const SHOW_SUGGESTION_DISPLAY_PRICE = "Click to show display price on suggested items"
export const HIDE_SUGGESTION_DISPLAY_PRICE = "Click to hide display price on suggested items"
export const VIEW_INVOICE_TEMPLATE = "View invoice template"
export const NOT_AN_INVOICE = "Not an invoice"
export const FILTER_TABLE_COLUMNS = "Filter table columns"
export const VOUCHER_CODE = "Voucher code"
export const GLOBAL_PRICE_DISPLAY = "Hide Display Prices"
export const SUGGESTED_ITEMS_PRICE_DISPLAY = "Hide Suggested Items Prices"
export const CUSTOM_LABEL_FOR_MARKET_PRICE = "Enter Text for Market Price"
export const PASSWORD_LESS_LOGIN = "Password less Login"
export const SEND_PASSWORD_CREATION_LINK = "Send Password Creation Link"
export const SEND_PASSWORD_VIA_EMAIL = "Send Password Via Email"
export const SEND_PASSWORD_VIA_SMS = "Send Password Via SMS"
export const AGREE_TO = "Agree to"
export const AGREE_TO_TERMS = "Terms of Use and Privacy Policy"
export const SEND_PASSWORD_LINK = "Send user a password creation link"
export const PASSWORD_NOTIFICATION = "Password Notification - Account Creation"
export const NO_SCHEDULE = "no-schedule"
export const VOUCHER_CODE_AUTH_REQUIRED = "To validate Voucher Code you must be logged in."
export const QR_CODE_SCANNER_CAMERA_ERROR = "Please allow camera access in order to scan the QR code.";
export const FB_MENU_TIMEOUT_DURATION = "Timeout Duration (In Minutes)"
export const PREFERRED_LANGUAGE = "Preferred Language"
export const UPLOAD_PHOTO_INFO = "Please upload images larger than TXT*TXTpx (Width * Height).";
export const UPLOAD_PHOTO_ERROR = "The selected image is too small. It must be at least TXT*TXTpx (Width * Height).";
export const UPLOAD_ABOUT_SCREEN_IMAGE = "Upload your about us screen image here";


// Titles
export const MANDATORY_POPUP_CONTENT = "All staff are required to be present for duty on this day.";
export const STAFF_COUNT = "Add Staff Count";
export const NO_WORK_SHIFT_ADDED = "No work shift added. Please add a new shift";
export const ADD_NEW_SHIFT = "Add New Shift";
export const WORK_TIME_SCHEDULE = "Employee Work Time Schedule";
export const PRINT_PREVIEW = "Print Preview";
export const SHIFT_SETTING = "Shift Setting";
export const ELIGIBLE_FOR_PAY = "Eligible for Holiday Pay";
export const BUSINESS_CLOSE = "Business Close";
export const HOLIDAY_MANAGEMENT = "Closure & Holiday Management";
export const HOLIDAY_LIST = "Closure & Holiday List";
export const HOLIDAY = "Holiday";
export const BRANDING = "Branding";
export const QR_CODE_LABELS = "QR Code Labels";
export const TOP_LABEL = "Top Label";
export const BOTTOM_LABEL = "Bottom Label";
export const TIME_OFF_REQUEST = "Time Off Request";
export const REQUEST_TIME_OFF = "Request Time Off";
export const TIME_OFF = "Time Off";
export const MY_TIME_OFF = "My Time Off";
export const EMPLOYEE_TIME_OFF = "Employee Time Off";
export const VIEW_DETAILS = "View Details";
export const AI_TEXT_GENERATOR = "Ai Text Generator";
export const AI_IMAGE_GENERATOR = "Ai Image Generator";
export const ADD_NEWS_AND_EVENTS = "Add News & Events";
export const URL_TEXT = "URL Text";
export const URL_SLUG = "URL Slug";
export const EVENT_CODE = "Event Code";
export const QR_CODE_LABEL = "Server Introduction Label";
export const USER_PAGE_TITLE = "Users";
export const ADD_CATEGORY_TITLE = "Add New Category";
export const ADD_CUSTOM_ROLE = "Add Custom Role";
export const ADD_CUSTOM_PERMISSION = "Add Custom Permission";
export const ADD_ITEM_TITLE = "Add Menu Item";
export const ADD_MENU_TITLE = "Add New Menu";
export const ADD_NEW_USER = "Add New User";
export const ADD_ADD_ONS = "Add Add-On";
export const ADD_INGREDIENTS = "Add Ingredients";
export const ADD_CUSTOM_MENU = "Add Custom Menu";
export const CREATE_CUSTOM_MENU = "Create Custom Menu";
export const KEY_INGREDIENTS = "Key Ingredients";
export const MANAGE_ADD_ONS = "Manage Add-Ons";
export const MANAGE_INGREDIENTS = "Manage Ingredients";
export const ADD_NEW_INGREDIENT = "Add New Ingredient";
export const ADD_NEW_ADD_ON = "Add New Add-On";
export const EDIT_ADD_ON = "Edit Add-On";
export const EDIT_INGREDIENT = "Edit Ingredient";
export const ADD_OFFERS = "Add Offers";
export const ADD_WINE_HISTORY_TITLE = "Add History";
export const CUSTOM_ROLES = "Custom Roles";
export const DEFAULT_ROLES = "Default Roles";
export const DOCUMENT_VIEWER = "Document Viewer";
export const EDIT_CATEGORY_TITLE = "Edit Category";
export const EDIT_ITEM_TITLE = "Edit Menu Item";
export const EDIT_MENU_TITLE = "Edit Menu";
export const EDIT_OFFER = "Edit Offer";
export const EDIT_USER = "Edit User";
export const EDIT_WINE_HISTORY_TITLE = "Edit History";
export const FILE_MANAGEMENT = "File Management"
export const GAME_ENABLED_HELP_TEXT = "Game After Survey";
export const GAME_MANAGEMENT = "Game Management";
export const GUEST_INFO = "Guest Information";
export const NEWS_AND_EVENTS_MANAGEMENT = "News & Events Management";
export const NEWS_AND_EVENTS = "News & Events";
export const MANAGE_COUPON = "Voucher";
export const MANAGE_USERS_TITLE = "Manage Users";
export const MENU_MANAGEMENT_TITLE = "F&B Menu Management";
export const SHIFT_CONFIGURATION_TITLE = "Shift Configuration";
export const ENABLE_DND = "Enable Drag & Drop";
export const DND = "Drag & Drop";
export const DISABLE_DND = "Disable Drag & Drop";
export const MOVE_CATEGORY = "Move Category";
export const MOVE_ITEM = "Move Item";
export const COPY_CATEGORY = "Copy Category";
export const COPY_ITEM = "Copy Item";
export const NEW_CATEGORY = "Category";
export const NEW_FOLDER = "New Folder";
export const NO_OFFER = "No Offer";
export const RENAME_FILE = "Rename File";
export const RENAME_FOLDER = "Rename Folder";
export const REPORTS = "Reports";
export const SELECT_CATEGORY = "Select Category";
export const SELECT_MENU = "Select Menu";
export const SURVEY_MANAGEMENT = "Survey Management";
export const SURVEY_QUESTION_SET = "Survey Question Set";
export const SURVEY_QUESTIONS = "Survey Questions";
export const USER_LIST = "User List";
export const ADD_ONS_LIST = "Add-Ons";
export const INGREDIENTS_LIST = "Ingredients List";
export const CUSTOM_MENU_LIST = "Custom Menu List";
export const USER_PHOTO = "Upload User Photo";
export const USER_ROLES_TITLE = "Manage Roles and Permissions";
export const UPLOADED_DOCUMENTS = "Uploaded Documents";
export const FILE_NAME = "File Name";
export const UPLOADED_BY = "Uploaded By";
export const FILE_TYPE = "File Type";
export const Date_N_Time = "Date & Time";
export const ACTION = "Actions";
export const CHOOSE_A_FILE_TYPE = "Choose a file type";
export const QR_CODE_SCANNER = "QR Code Scanner";
export const GLOBAL_CONFIGURATION = "F&B Configuration";
export const CUSTOM_MENU_CONFIGURATION = "Custom Menu Configuration";
export const USER_CONFIGURATION = "User Configuration";
export const EULA = "END USER LICENSE AGREEMENT (EULA)";
export const MENU_SCHEDULE = "Schedule";
export const WELCOME_SCREEN = "Welcome Screen";
export const WELCOME_TEXT = "Welcome Text";
export const WELCOME_IMAGE = "Welcome Image";
export const DAILY = "Daily";
export const REDIRECTION_FROM_ITEM_TITLE = "Item Unavailable";
export const CATEGORY_FILTER = "Category Filter";
export const TAG_FILTER = "Tag Filter";
export const ADD_ON_LIST = "Add-Ons List";
export const ADD_ONS = "Add-Ons";
export const ADD_ADD_ON = "Add Add-Ons";
export const ADD_ONS_GROUP = "Add-Ons Group";
export const ADD_ON_GROUP = "Add-On Group";
export const ADD_ADD_ONS_GROUP = "Add Add-On Group";
export const ADD_NEW_ADD_ON_GROUP = "Add New Add-On Group";
export const EDIT_ADD_ON_GROUP = "Edit Add-On Group"
export const INCLUDED_ADD_ONS = "Included Add-Ons"
export const ADD_ON_GROUP_NAME = "Group Name"
export const EMPTY_ADD_ON_GROUP = "No 'Add-on Group' added"
export const EACH = "each"
export const ADD_ADDONS = "Add Addons";
export const TITLE_SHIFT_SETTING = "This configuration page in our restaurant app empowers managers and administrators to effortlessly set up and manage work shifts for their staff. With this interface, you can define shifts and establish schedules, ensuring smooth operations and optimal coverage at your establishment. Simplify your workforce management and streamline scheduling with this configuration page.";
export const AVAILABILITY = "Availability";
export const AVAILABILITY_LIST_TITLE = "Availability List";
export const SET_AVAILABILITY = "Set Availability";
export const SET_TEMP_AVAILABILITY = "Set Temporary Availability";
export const TEMP_AVAILABILITY = "Temporary Availability";
export const REGULAR_AVAILABILITY = "Regular Availability";
export const VIEW_AVAILABILITY = "View Availability";
export const CURRENT_WEEK = "Current Week";
export const AVAILABILITY_REQUESTS = "Availability Requests";
export const MY_AVAILABILITY = "My Availability";
export const HOLIDAYS = "Holidays";
export const PROFILE_SETTINGS = "Profile Settings";

// Warning Messages
export const STAFF_SCHEDULE_DISCREPANCY = "There is a discrepancy between the expected and current staff counts. Please review and correct the schedule before proceeding.";
export const CONFIRM_PUBLISH_SCHEDULE = "You are about to publish a new work schedule. This will replace any existing schedule for this week. Do you want to proceed?";
export const CONFIRM_UPDATE_SCHEDULE = "Changes won't be visible until published. Are you sure you want to edit the published schedule?";
export const CONFIRM_CLEAR_DRAFT = "You are about to clear the draft schedule. Any unpublished changes will be lost. Do you want to proceed?";
export const NO_SHIFT_ASSIGNED = "You have no shift assigned for the day";
export const CANCEL_WARNING = "Are you sure you want to cancel ?";
export const DELETE_WARNING = "Are you sure you want to delete ?";
export const INACTIVATE_USER = "Are you sure you want to inactivate this user?"
export const DEFAULT_QUESTION = "Are you sure you want to make question set as default?"
export const ACTIVATE_SURVEY_SET = "Are you sure you want to active this question set?"
export const ACTIVATE_USER = "Are you sure you want to activate this user?"
export const UPDATE_WARNING = "Are you sure you want to continue without saving ?";
export const CLEAR_CUSTOM_ROLE_WARNING = "This will clear role name and description.";
export const CLEAR_DEFAULT_ROLE_WARNING = "This will clear role description.";
export const MENU_DELETE_WARNING = "By deleting the menu, already linked categories and items under the menu also get deleted. Are you sure you want to delete?";
export const DO_YOU_WANT_TO_PROCEED = "Do you want to proceed?";
export const CATEGORY_DELETE_WARNING = "By deleting the category, already linked item/s under the category also get deleted. Are you sure you want to delete?";
export const PASSWORD_LESS_LOGIN_WARNING_1 = "Please note that you have not specified a password while attempting to create a user account.";
export const PASSWORD_LESS_LOGIN_WARNING_2 = "If the user account requires a password in the future, the administrator can use the edit option to send a 'set password' link to the user's email.";
export const PASSWORD_LESS_LOGIN_WARNING_3 = "With this link, the user can create their own password for their account.";
export const DEACTIVATE_MENU = "By deactivating the menu, category and items under the menu will not be visible to the Guest";
export const ACTIVATE_MENU = "By activating the menu, category and items under the menu will be visible to the Guest.";
export const DEACTIVATE_CATEGORY = "By deactivating the category, items under the category will not be visible to the Guest.";
export const ACTIVATE_CATEGORY = "By activating the category, items under the category will be visible to the Guest.";
export const REDEEM_COUPON = "Are you sure you want to redeem?";
export const ACTIVATE_ITEM = "Are you sure you want to activate the item ?";
export const DEACTIVATE_ITEM = "Are you sure you want to deactivate the item ?";
export const FILE_NOT_SUPPORTED = "Unsupported file(s) received. Please try uploading the listed file types.";
export const FILE_UPLOAD_EXIT_WARNING = "File uploading is in progress. Are you sure you want to exit?";
export const DOCUMENT_IDENTIFIED_CORRECTLY = "Please confirm the file type"
export const DOCUMENT_IDENTIFIED_INCORRECTLY = "Automatically identified file type is incorrect"
export const VOUCHER_DETAILS_ERROR = "Unable to find voucher code data"
export const WELCOME_SCREEN_UPDATED = "Your welcome screen has been successfully updated."
export const FILE_UPLOAD_LIMIT = "The maximum allowed file size for image uploads is 5MB."
export const REDIRECTION_FROM_ITEM_WARNING = "We apologize for the inconvenience, but the menu you are looking for is currently unavailable."
export const RECAPTCHA_VALIDATION_FAILED = "Recaptcha validation failed"
export const DELETE_TEMPLATE_WARNING = "Are you sure you want to remove this festival template ?";
export const ABOUT_US_IMAGE_PLACEMENT_MESSAGE_1 = "For top placement, please ensure the uploaded image is suitable as a banner.";
export const ABOUT_US_IMAGE_PLACEMENT_MESSAGE_2 = " For left or right placement, a standard-sized image is recommended.";

export enum NEWS_N_EVENTS_WARNINGS {
    END_DATE_AFTER_START_ERROR = 'End date should be after start date',
    END_DATE_REQUIRED = 'End date is required',
    END_TIME_AFTER_START_ERROR = 'End time should be after start time',
    END_TIME_IN_PAST_ERROR = 'End time should not be in the past',
    END_TIME_REQUIRED = 'End time is required',
    EVENT_DATE_REQUIRED = 'Event date is required',
    EXPIRY_DATE_ERROR = 'Expiry date should be today or a future date',
    EXPIRY_DATE_GREATER_ERROR = 'Expiry date should be after the publish date',
    EXPIRY_DATE_REQUIRED = 'Expiry date is required',
    HEADING_DATE_REQUIRED = 'Title must be at least 5 characters long',
    PUBLISH_DATE_ERROR = 'Publish date should be today or a future date',
    PUBLISH_DATE_REQUIRED = 'Publish date is required',
    START_DATE_IN_PAST_ERROR = 'Start date should be before end date',
    START_TIME_AFTER_END_ERROR = 'Start time should be before end time',
    START_TIME_IN_PAST_ERROR = 'Start time should not be in the past',
    START_TIME_REQUIRED = 'Start time is required',
    URL_ERROR = 'URL is required',
    EVENT_RECURRENCE = 'Event recurrence required',
};

// Response Messages
export const DELETE_SUCCESS = "Successfully deleted the role.";
export const ROLE_ADD_SUCCESS = "Successfully added the role.";
export const ROLE_UPDATE_SUCCESS = "Successfully updated.";
export const USER_ADD_SUCCESS = "Successfully added new user.";
export const USER_UPDATE_SUCCESS = "Successfully updated the user.";
export const USER_UPLOAD_SUCCESS = "Successfully uploaded the photo.";
export const USER_VIDEO_UPLOAD_SUCCESS = "Successfully uploaded the video.";
export const USER_DELETE_SUCCESS = "Successfully deleted the user.";
export const USER_ACTIVATE_SUCCESS = "Successfully activated the user.";
export const USER_INACTIVE_SUCCESS = "Successfully deactivated the user.";
export const PASSWORD_RESET_MAIL_SUCCESS = "Password reset email sent successfully."
export const FORCE_LOGOUT = "force-logout"
export const CHANNEL = "intercom"
export const NAVIGATE_TO_ROUTE = "navigate-to-route"
export const LOGGED_IN = "logged_in"
export const RESET_FONT_SCALE = "reset-font-scale"

// Error Messages
export const LOGO_FORMAT_ERROR = "You can only upload JPG/PNG file!";
export const LOGO_SIZE_ERROR = "Image must smaller than";
export const LOGIN_WITH_LINK_ERROR = "Login with link failed. Please try again.";
export const LOGIN_ERROR = "Invalid credentials. Please try again.";
export const FORGOT_PASSWORD_ERROR = "Password reset failed.";
export const PASSWORD_MISMATCH_ERROR = "Passwords do not match.";
export const PASSWORD_WEAK_ERROR = "Password must be minimum 6 characters long, contains at least one number, one uppercase letter, one lowercase letter, and one symbol.";
export const ROLE_DELETE_ERROR = "Error deleting the role. Please try again.";
export const ROLE_ADD_ERROR = "Error adding the role. Please try again.";
export const ROLE_UPDATE_ERROR = "Error updating the role. Please try again.";
export const FULL_NAME_ERROR = "Please enter a valid name.";
export const ZIP_CODE_ERROR = "Please enter a valid zip code.";
export const EMAIL_ERROR = "Please enter a valid email.";
export const MOBILE_ERROR = "Please enter a valid mobile number.";
export const DATE_ERROR = "Please enter a valid date.";
export const POS_ERROR = "Please enter a valid POS Number.";
export const ROLE_ERROR = "Please choose a role.";
export const PERMISSION_ERROR = "Please choose a permission.";
export const PASSWORD_ERROR = "Please enter a valid password.";
export const USER_ADD_ERROR = "Error adding user.";
export const USERNAME_ERROR = "Please enter a valid username";
export const USER_UPDATE_ERROR = "Error updating user.";
export const USER_DELETE_ERROR = "Error deleting user.";
export const USER_UPLOAD_PHOTO_ERROR = "Error uploading photo."
export const USER_UPLOAD_VIDEO_ERROR = "Error uploading video."
export const USER_ACTIVATE_ERROR = "Error activating / deactivating user.";
export const UPLOAD_PHOTO_TYPE_ERROR = "The selected file is not an image. Please try again.";
export const UPLOAD_VIDEO_TYPE_ERROR = "The selected file is not a video. Please try again.";
export const UNKNOWN_ERROR = "Something went wrong. Please try again.";
export const NO_QUESTION_SET = "No question set found.";
export const NO_SURVEY_REPORTS = "No survey reports found.";
export const FILL_REQUIRED_FIELDS = "Fill all the required fields.";
export const NO_OFFERS = "No offers found.";
export const NO_COUPONS = "No active vouchers found.";
export const ERROR_BOUNDARY_TITLE = "Apologies, something went wrong while loading the page. Please try refreshing or use the buttons to navigate.";

// Help Content
export const HELP_USER = "Add staff with single or multiple positions. POS # should be unique and set up with a user name. Administrator/ Manager should set up password for user name that will not be repeated in the future. QR code will only be available for servers.";
export const HELP_MENU = "Manage menus by adding, editing or deleting menus. Manage the menu item's in each category. Enable or disable menus within checkbox. Change menu order by dragging and dropping menus to change order displayed on guest view. Menu name and image will appear on customer-facing menu.";
export const HELP_CATEGORY = "Each menu has categories and can be changed by adding, editing or deleting categories. Use categories to group similar menu items together to be shown below. Enable or disable categories within checkbox. Change categories order by dragging and dropping categories to change order displayed on guest view. Category name and image will appear on customer-facing menu.";
export const HELP_ITEMS = "Items are the food or beverage offered on your menu. Items can be grouped in categories or listed separately. Menu items can be enabled or disabled using the checkbox. When editing menu items a new screen opens allowing you to provide detailed information about the food or beverage item selected. Change items order by dragging and dropping items to change order on guest view. Items name and image will appear on customer-facing menu.";
export const HELP_USER_PHOTO = "Resolution should be 200px * 200px (Width * Height).";
export const HELP_DEFAULT_ROLES = "Roles enable functionality for restaurant staff. Default roles are provided out-of-the-box to manage core system functionality. ";
export const HELP_CUSTOM_ROLES = "Custom roles allow restaurant owners and managers the ability to fine tune what specific functionality can be performed by restaurant staff.  Select specific functionality to enable for each Custom Role.  Provide a description to store additional context for internal use.  Roles should align with staffing responsibilities.";
export const HELP_NEW_CATEGORY = "Category description is displayed below the category title on the customer-facing menu.";
export const HELP_ITEM_NAME = "Provide a name for the menu item.";
export const HELP_ITEM_TAG = "Create custom tags for menu items. Separate multiple tags with a comma. Tags are displayed on the customer-facing menu with a yellow pill below the Item Title.";
export const HELP_UNAVAILABLE = "Marking as Unavailable keeps the menu item displayed on the customer-facing menu but notifies that the item is not available in red text.";
export const HELP_MARKET_PRICE = "When checked, the price of this item will be hidden and a label 'Market price' will be displayed in the guest menu";
export const HELP_ADD_PRICE = "Set prices for menu items.  A menu item can have one or multiple prices.  Set the display price to be displayed on the menu category view for customers.  If a display price is not selected, the lowest price will be displayed on the menu category view.  Menu items may have multiple versions of prices for variations such as small, medium or large; or different prices for Chicken, Steak or Veal. Menu items may also have a standard price plus additional prices for upgraded versions of the meal.";
export const HELP_ADD_ONS_PRICE = "Provide additional paired items and prices.  For example: add a side salad or extra meat.";
export const HELP_ADD_INGREDIENTS = "This input field allows you to enter the ingredients for a particular dish on the restaurant's food menu. Use this field to specify the list of ingredients that make up the dish, ensuring accuracy and clarity for customers.";
export const HELP_ADDITIONAL_INFO = "Additional information regarding the menu item should be added here. ";
export const HELP_SUGGESTION_ITEMS = "Useful to recommend pairings with a particular beverage such as a wine pairing.";
export const HELP_FOOD_SUGGESTION_ITEMS = "Useful to recommend pairings with a particular food item.";
export const HELP_HISTORY = "Use the Add History option to add specific history related to the particular beverage item.  This is useful for Wine and Spirits.";
export const HELP_QUESTION_SET = "Add, edit or delete question sets for the guest satisfaction survey.  Only one question set may be active at the same time.  Duplicate question sets to make temporary changes or build from existing survey questions without impacting current surveys.";
export const HELP_GAME_AFTER_SURVEY = "Enable games after submitting the guest satisfaction survey.";
export const HELP_GUEST_INFO = "Details from the first section of the survey will be shown here.";
export const HELP_GAME = "Admin/Manager can add, update or delete offers. Parameters that impact how the game is won can be set.";
export const HELP_COUPON_VALIDITY = "The number of days an offer is valid beginning from the date the voucher is sent to a guest.";
export const HELP_MAX_COUPON = "The maximum number of vouchers which can be redeemed for an offer.";
export const HELP_MAX_FREQUENCY = "The maximum number of vouchers allowed to be redeemed in a time frame.";
export const HELP_ZIP_RADIUS = "When only the zip code is defined, offer will only be available to guests who have the same zip code. When the zip code and radius is defined, the system will award offers to guests that fall under a particular zip code within a set radius.";
export const HELP_MIN_VISIT = "Offers will only apply to guests which have at least the specified minimum visit count within a period of 90 days.";
export const HELP_WEIGHTAGE = "Higher weight means a higher probability of winning the offer.";
export const HELP_NO_OFFER = "Provide the text that should be displayed when no offer is won and set the number of times this text will be displayed randomly on the wheel.";
export const HELP_COUPONS = "All vouchers sent to guests.";
export const HELP_REPORTS = "Survey details and results will be displayed here.";
export const HELP_ADD_QUESTION = "Only 'Star Ratings, Yes/No and Matrix Table' survey answers are considered for dashboard reporting metrics.";
export const HELP_MOVE_ITEM = "Please select the desired 'menu' and 'category' if you wish to move this item.";
export const HELP_MOVE_CATEGORY = "Please select the desired 'menu' if you wish to move this category.";
export const HELP_COPY_ITEM = "Please select the desired 'menu' and 'category' if you wish to copy this item.";
export const HELP_COPY_CATEGORY = "Please select the desired 'menu' if you wish to copy this category.";
export const HELP_GLOBAL_CONFIG = "The F&B configuration is a place for managing system settings from a centralized location.";
export const HELP_CUSTOM_MENU_CONFIG = "The custom menu configuration is a place for managing per-menu settings from a centralized location.";
export const HELP_USER_CONFIG = "The user configuration is a place for managing user settings from a centralized location.";
export const DND_HELP = "Please note that changes made in this setting will not be saved.";
export const SEND_PASSWORD_CREATION_LINK_HELP = "By selecting this option, the user will receive a link from the application that will enable them to create a password for their login.";
export const PASSWORD_LESS_LOGIN_HELP = "By selecting this option, the user will not have any password for login. They can login with POS number only.";
export const MENU_SCHEDULE_HELP = "A menu schedule configuration allows you to set up the timing and availability of menus in your system. With this feature, you can easily specify when menus should be available for customers to order from and when they should be closed. This can be particularly useful if you have specific menu items that are only available during certain times of the day, or if you want to limit the availability of certain menu items to manage inventory or sales.";
export const ADD_ON_HELP = "Use this page to view, create, edit, and delete add-ons and add-on groups, listed in the 'Add-On List' and 'Add-Ons Group' table";
export const INGREDIENTS_HELP = "Use this page to view, create, edit, and delete ingredients, listed in the 'Ingredients List' table";
export const CUSTOM_MENU_HELP = "Use this page to view, create, edit, and delete custom menus, listed in the 'Custom Menu List' table";
export const CUSTOM_MENU_DESCRIPTION_HELP = "The description below will be prominently displayed on the front page of the Custom Menu.";
export const ADD_EXISTING_HELP = "To select multiple items, press and hold the Ctrl key while clicking on each item.";
export const NEWS_AND_EVENTS_HELP = "The news and events management page is a crucial component of our system, empowering you to efficiently create, update, and delete news and events. This functionality is designed to cater specifically to the information you want to showcase on the 'News & Events' page, ensuring that your guests stay informed about the latest happenings. With this feature, you have complete control over the content displayed, allowing you to provide a seamless and engaging experience to all users.";
export const HELP_EVENT_TAG = "Badges that will be displayed in the event card";
export const HELP_EVENT_LOCATION = "Provide the location where the event is conducted";
export const HELP_EVENT_URL_TEXT = "The text that wraps the link to the event";
export const HELP_EVENT_URL_SLUG = "Last part of the URL address that serves as a unique identifier of the page";
export const HELP_EVENT_AUTO_PUBLISH_DATE = "The event will be automatically published in the events page in the specified date";
export const HELP_NEWS_AUTO_PUBLISH_DATE = "The news will be removed from the News and Events page in the specified date";
export const HELP_EVENT_START_DATE = "The date on which the event starts";
export const HELP_EVENT_END_DATE = "The date on which the event ends";
export const HELP_EVENT_START_TIME = "The time at which the event starts";
export const HELP_EVENT_END_TIME = "The time at which the event ends";
export const HELP_USERNAME = "Minimum 3 characters; use underscores (_) or hyphens (-) only; emails also accepted.";
export const HELP_TIME_OFF_PAGE = "The 'Employee Time Off Request' page exclusively facilitates leave requests and displays their current approval status";
export const HELP_BRANDING_PAGE = "Optimize your mobile logo for QR codes, ensuring it's a square design with dimensions up to 60x60 pixels. The file size should not exceed 1MB. For application headers, use the web logo with a recommended size of 200x50 pixels, keeping the file size under 1MB.";
export const HELP_TIME_OFF_HISTORY = "The 'Employee Time Off' page exclusively facilitates your leave requests and displays the current approval status for each request and allows you to create, manage, and track your time off.";
export const HELP_HOLIDAY_PAGE = "Welcome to the Closure & Holiday page! Easily manage your business's closed days, including national, festive, and regional holidays.";
export const HELP_HOLIDAY_LIST = "Stay informed about upcoming holidays and special events!. Prepare for busy times and unique promotions ahead.";
export const AVAILABILITY_HELP = "Welcome to Availability Preferences! Use this section to set your preferred workdays and times. You can indicate your availability for specific days of the week by selecting the checkboxes next to each day.";
export const AVAILABILITY_REQUESTS_HELP = "Welcome to Availability Requests! Here, you can manage requests from team members for their availability. Review each request carefully and either approve or reject it based on your team's scheduling needs. You can take actions on each request by selecting the corresponding option.";
export const VIEW_AVAILABILITY_HELP = "Provides a visual representation of user availability, mandatory days, and total counts for a given week. Use this heat map to quickly identify patterns, track mandatory participation days, and monitor overall user engagement.";
export const WORK_TIME_SCHEDULE_HELP = "Welcome to the Shift Assignment page! Here, you can efficiently assign employees to work shifts.";
export const PRINT_PREVIEW_HELP = "Welcome to the Print Preview page! Here, you can view employees' published shifts, categorized by their assigned roles.";
export const HELP_PROFILE_SETTINGS = "Welcome to your profile page! Here you can manage your account settings, update your personal information.";

export const PWA_INSTALL = "Install App";
export const PWA_INSTALL_MSG = "Install the app on your device to easily access it anytime. No app store, No download.";