import { Button, Drawer } from 'antd'
import React from 'react'
import { PageMode } from '../../constants/constants'
import { ToastMessageProps } from '../../constants/staticTypes'
import { REQUEST_TIME_OFF, TIME_OFF_REQUEST } from '../../constants/strings'
import { JTranslation } from '../../helpers/jTranslate'
import { CloseOutlined } from "@ant-design/icons"
import TimeOffRequestForm from '../time_off_request/TimeOffRequestForm'

type Props = {
    pageMode: PageMode.ADD | PageMode.EDIT
    setToastMessage: React.Dispatch<React.SetStateAction<ToastMessageProps>>
    showSchedulerTimeOffSideBar: boolean
    setShowSchedulerTimeOffSideBar: React.Dispatch<React.SetStateAction<boolean>>
    onTimeOffSuccess?: (message?: string, variant?: string) => void 
}

function SchedulerTimeOffSidebar({ pageMode, setToastMessage, showSchedulerTimeOffSideBar, setShowSchedulerTimeOffSideBar, onTimeOffSuccess }: Props) {
  return (
    <Drawer
      open={showSchedulerTimeOffSideBar}
      onClose={() => {
        setShowSchedulerTimeOffSideBar(false);
      }}
      width={630}
      placement="right"
      className="custom-offcanvas"
      zIndex={1042}
      title={
        pageMode === PageMode.ADD ? (
          <JTranslation typeCase="pascal" text={REQUEST_TIME_OFF} />
        ) : (
          <JTranslation typeCase="pascal" text={`Edit ${TIME_OFF_REQUEST}`} />
        )
      }
      closable={false}
      maskClosable={false}
    >
      <div style={{ position: "absolute", top: 15, right: 16 }}>
        <Button
          type="text"
          icon={<CloseOutlined />}
          onClick={() => setShowSchedulerTimeOffSideBar(false)}
        />
      </div>

      <div className="row">
        <div className=" col-md-12 col-lg-12 mb-3 ">
          <TimeOffRequestForm
            onClose={() => setShowSchedulerTimeOffSideBar(false)}
            pageMode={pageMode}
            setToastMessage={setToastMessage}
            parent={"scheduler"}
            onTimeOffSuccess={onTimeOffSuccess}
          />
        </div>
      </div>
    </Drawer>
  );
}

export default SchedulerTimeOffSidebar