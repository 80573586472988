import { useContext, useState, useEffect, useRef } from 'react'
import { useScroll, useWindowSize } from 'react-use'
import { MOBILE_VIEW_BREAKPOINT } from '../../constants/constants'
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate'
import { Badge } from 'primereact/badge'
import { FaSearch } from 'react-icons/fa'
import { DebounceInput } from 'react-debounce-input'
import { GetNotificationsApiParams, NotificationObject } from '../../constants/staticTypes'
import { getIconName, getNavigateUrl, getNotificationListView, markAllNotificationsAsRead, markNotificationAsRead } from '../../helpers/notificationHelper'
import { convertUTCtoLocalByOffset, customDeepClone, getStaffId } from '../../helpers/utils'
import { useQueryClient } from 'react-query'
import { AxiosResponse } from 'axios'
import { Button } from 'primereact/button'
import WarningModal from '../warning_modal/WarningModal'
import { useNavigate } from 'react-router-dom'
import { NOTIFICATIONS_LIST, UNREAD_NOTIFICATIONS_LIST } from '../../constants/queryKeys'
import noNotificationImage from '../../assets/images/no-notifications.png'
import useQueryHook from '../../hooks/useQueryHook'

let fetchingPaginatedData = true

function NotificationsListGrid() {
	const id = getStaffId()
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const notificationPanelRef = useRef<HTMLDivElement | null>(null)
	const { y } = useScroll(notificationPanelRef)
	const { width } = useWindowSize()
	const breakPoint = MOBILE_VIEW_BREAKPOINT
	const translationContext = useContext(TranslationContext)
	const { targetLanguage } = translationContext
	const paginationLimit = 10

	const [notificationList, setNotificationList] = useState<NotificationObject[]>([])
	const [unreadNotificationList, setUnreadNotificationList] = useState<NotificationObject[]>([])
	const [translationText, setTranslatedText] = useState<{
		search: string
		paginationTemplate: string
		emptyMessage: string
		markAllAsRead: string
		showOnlyUnread: string
	}>({
		search: 'Search',
		paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
		emptyMessage: 'No notification found.',
		markAllAsRead: 'Mark all as read',
		showOnlyUnread: 'Only show unread',
	})
	const [showTitle, setShowTitle] = useState(true)
	const [lastKey, setLastKey] = useState('')
	const [unreadBadge, setUnreadBadge] = useState(0)
	const [isLoading, setIsLoading] = useState(false)
	const [showOnlyUnread, setShowOnlyUnread] = useState(false)
	const [search, setSearch] = useState('')
	const [showWarning, setShowWarning] = useState(false)

	const onFetchEventsSuccess = (res: AxiosResponse<any, any>) => {
		const newData: NotificationObject[] = res.data.data.list
		const key = res.data.data.pagination.lastKey
		setNotificationList(data => [...data, ...newData])
		setLastKey(key)
		fetchingPaginatedData = true
		setIsLoading(false)
	}

	const onError = () => {
		setIsLoading(false)
		setNotificationList([])
	}

	const getData = useQueryHook(
		NOTIFICATIONS_LIST,
		() => getNotificationListView(queryClient, { search: '', lastKey: '', limit: paginationLimit }, id),
		onFetchEventsSuccess,
		onError,
		false,
		// false
	)

	const getMoreData = () => {
		const param: GetNotificationsApiParams = { search: search, lastKey: lastKey, limit: paginationLimit }
		// fetch paginated data
		if (fetchingPaginatedData) {
			setIsLoading(true)
			getNotificationListView(queryClient, param, id)
				.then(onFetchEventsSuccess)
				.catch(onError)
		}
		fetchingPaginatedData = false
	}

	// mark all as read
	const markAllAsRead = () => {
		setShowWarning(false)
		setIsLoading(true)
		markAllNotificationsAsRead(queryClient, id)
			.then(() => {
				setNotificationList([])
				getData.refetch()
				queryClient.refetchQueries([UNREAD_NOTIFICATIONS_LIST])
			})
			.catch(onError)
	}

	// mark notification as read and navigate
	const markAsRead = (notification: NotificationObject) => {
		setIsLoading(true)
		const params = {
			staffId: id,
			notificationIds: [notification.id],
			readStatus: true,
		}
		markNotificationAsRead(queryClient, params)
			.then(() => {
				queryClient.refetchQueries([UNREAD_NOTIFICATIONS_LIST])
			})
			.catch((response) => {
				console.error(`failed to mark as read`, response)
			})
			.finally(() => {
				setIsLoading(false)
				navigate(getNavigateUrl(notification))
			})
	}

	// Translate on load and language switch
	useEffect(() => {
		const fetchTranslation = async () => {
			try {
				const translations = await Promise.all([
					jTranslationText({ text: 'Search', typeCase: 'pascal', translationContext }),
					jTranslationText({ text: 'Showing 1 to 2 of 3 items', typeCase: 'pascal', translationContext }),
					jTranslationText({ text: 'No notification found.', typeCase: 'pascal', translationContext }),
					jTranslationText({ text: 'Mark all as read', typeCase: 'capitalize', translationContext }),
					jTranslationText({ text: 'Only show unread', typeCase: 'capitalize', translationContext }),
				])

				setTranslatedText({
					search: translations[0] ?? 'Search',
					paginationTemplate:
						translations[1]
							?.replace('1', '{first}')
							?.replace('2', '{last}')
							?.replace('3', '{totalRecords}') ?? 'Showing {first} to {last} of {totalRecords} items',
					emptyMessage: translations[2] ?? 'No notification found.',
					markAllAsRead: translations[3] ?? 'Mark all as read',
					showOnlyUnread: translations[4] ?? 'Only show unread',
				})
			} catch {
				setTranslatedText({
					search: 'Search',
					paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
					emptyMessage: 'No notification found.',
					markAllAsRead: 'Mark all as read',
					showOnlyUnread: 'Only show unread',
				})
			}
		}
		fetchTranslation()
	}, [targetLanguage])

	// set unread badge
	useEffect(() => {
		const clonedNotifications = customDeepClone(notificationList) as NotificationObject[]
		const unreadNotificationList = clonedNotifications.filter((notification) => !notification.isRead)
		setUnreadNotificationList(unreadNotificationList)
		setUnreadBadge(unreadNotificationList.length)
	}, [notificationList])

	// On scroll end of list
	useEffect(() => {
		if (
			notificationPanelRef?.current &&
			y > 0 &&
			notificationPanelRef?.current?.scrollHeight - y === notificationPanelRef?.current?.clientHeight
		) {
			getMoreData()
		}
	}, [y])

	// on search
	useEffect(() => {
		if(search?.trim()?.length === 0) return;

		getMoreData()
	}, [search])

	// on load
	useEffect(() => {
		getData.refetch()
	}, [])

	return (
		<>
			{/* mark all as read request popup */}
			<WarningModal
				show={showWarning}
				title={'Are you sure you want to mark all notifications as read?'}
				onHide={() => setShowWarning(false)}
				callback={() => {
					markAllAsRead()
				}}
			/>
			<div className="col-md-12 col-lg-12 mb-3 h-100 ">
				<div className="card h-100 ">
					<div className="card-header flex-grow-0">
						<div className="d-flex align-items-center">
							<div className="flex-grow-1">
								{showTitle && (
									<h5 className="mb-0 title d-flex align-items-center">
										<JTranslation typeCase="pascal" text={'My Notifications'} />
										{isLoading ? (
											<span className="ms-2">
												<i className="pi pi-spin pi-spinner"></i>
											</span>
										) : (
											unreadBadge > 0 && (
												<Badge className="ms-2" value={unreadBadge >=10 ? '10+' : unreadBadge} severity="warning"></Badge>
											)
										)}
									</h5>
								)}
							</div>
							<div className="flex-grow-1 d-flex justify-content-end ">
								<div className="col-md-4">
									<div
										className={`form-group has-search ${!showTitle ? '' : 'search-small-box'}`}
									>
										<span className="fa fa-search form-control-feedback">
											<FaSearch />{' '}
										</span>
										<DebounceInput
											onFocusCapture={() => {
												breakPoint >= width && setShowTitle(false)
											}}
											onBlurCapture={(event: any) => {
												if (breakPoint >= width && event.target.value.length === 0) {
													setShowTitle(true)
												}
											}}
											autoComplete="off"
											className="form-control "
											placeholder={translationText.search}
											minLength={0}
											debounceTimeout={500}
											data-testid="search"
											onChange={(e) => {
												setSearch(e.target.value)
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="card-body flex-grow-1 overflow-hidden">
						<div className="survey-section h-100">
							{notificationList.length > 0 && (
								<div className="d-flex justify-content-end align-items-center px-3 pb-3">
									<Button
										size="small"
										className="btn btn-custom-primary-outline"
										outlined
										onClick={() => setShowWarning(true)}
										disabled={unreadBadge === 0}
									>
										<span className="btn-text">{translationText.markAllAsRead}</span>
									</Button>
									<Button
										size="small"
										className={
											showOnlyUnread
												? 'btn btn-custom-primary active ms-3'
												: 'btn btn-custom-primary-outline ms-3'
										}
										outlined
										onClick={() => {
											setShowOnlyUnread(!showOnlyUnread)
										}}
									>
										<span className="btn-text">{translationText.showOnlyUnread}</span>
									</Button>
								</div>
							)}
							<div
								className="notification-panel notification-center pb-5 h-100"
								ref={notificationPanelRef}
							>
								{!showOnlyUnread &&
									notificationList.map((notification, index) => (
										<div
											key={index}
											className="col-lg-12 mb-2 px-3"
											role="button"
											onClick={() => {
												if(notification.isRead) {
													navigate(getNavigateUrl(notification))
												} else {
													markAsRead(notification)
												}
											}}
										>
											<div
												className={`notification-list ${notification?.isRead ? 'read' : 'unread'
													}`}
											>
												<div className="notification-type">
													<i className={getIconName(notification?.topic)}></i>
												</div>
												<div className="ps-3 py-2 notification-message">
													<p className="m-0">
														<b>{notification?.notification?.title}</b>
														<small className="ps-3 text-muted">
															{convertUTCtoLocalByOffset(notification?.timestamp, {
																humanizeFormat: true,
															})}
														</small>
													</p>
													<p className="m-0 pt-2">{notification?.notification?.body}</p>
												</div>
											</div>
										</div>
									))}

								{showOnlyUnread &&
									unreadNotificationList.map((notification, index) => (
										<div 
											key={index} 
											className="col-lg-12 mb-2 px-3"
											role="button"
											onClick={() => {
												if(notification.isRead) {
													navigate(getNavigateUrl(notification))
												} else {
													markAsRead(notification)
												}
											}}
										>
											<div className={`notification-list ${notification?.isRead ? 'read' : 'unread'}`}>
												<div className="notification-type">
													<i className={getIconName(notification?.topic)}></i>
												</div>
												<div className="ps-3 py-2 notification-message">
													<p className="m-0">
														<b>{notification?.notification?.title}</b>
														<small className="ps-3 text-muted">
															{convertUTCtoLocalByOffset(notification?.timestamp, {
																humanizeFormat: true,
															})}
														</small>
													</p>
													<p className="m-0 pt-2">{notification?.notification?.body}</p>
												</div>
											</div>
										</div>
									))}

								{notificationList.length === 0 && getData.isFetched && !getData.isFetching && (
									<div className="h-100 d-flex flex-column align-items-center justify-content-center">
										<div className="flex-grow-1 d-flex align-items-center justify-content-center p-3">
											<img
												className="img-fluid"
												src={noNotificationImage}
												alt="no-notifications"
											/>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default NotificationsListGrid
