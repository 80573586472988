import { useContext, useEffect, useState } from 'react'
import './styles/welcome-page.scss'
import { AxiosResponse } from 'axios'
import { Buffer } from 'buffer'
import { FESTIVAL_TEMPLATES, MENU_RERENDER_INTERVAL } from '../../constants/constants'
import { NO_SCHEDULE } from '../../constants/strings'
import { getDeviceId, setDeviceId, trackGuestUser } from '../../helpers/userTrackingHelper'
import HeartIcon from '../../components/celebrations/valentinesDay/HeartIcon'

import {
  getMenuItemsPublic,
  saveMenuSelection,
  setMenuData,
  SliderSkeleton,
  shouldShowMenuCard,
} from '../../helpers/menuViewHelper'

import {
  getPublicAboutUsScreenConfig,
  getPublicWelcomeScreenConfig,
  initialWelcomeImageData,
  welcomeSliderSettings,
} from '../../helpers/welcomeConfigHelper'

import {
  AboutUsConfig,
  BrandingData,
  FnBGlobalSetting,
  Menu,
  UploadedWelcomeImage,
  WelcomeConfig,
} from '../../constants/staticTypes'

import {
  PUBLIC_MENU,
  USER_TRACKING,
  PUBLIC_WELCOME_CONFIG,
  BRANDING_INFO,
  TENANT_INFO,
  ABOUT_US_INFO,
} from '../../constants/queryKeys'
import { CommonCtx } from '../../context/CommonCtxProvider'
import { isQrScanPresent, unescapeHtml } from '../../helpers/utils'
import { JTranslation } from '../../helpers/jTranslate'
import { playSound } from '../../components/celebrations/PlaySound'
import { routes } from '../../constants/routes'
import { Skeleton } from 'primereact/skeleton'
import { UAParser } from 'ua-parser-js'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'
import { useTrackEvent } from '../../hooks/useTrackEvent'
import HeaderDropDown from '../../components/header/HeaderDropdown'
import LanguageSwitcher from '../../components/header/LanguageSwitcher'
import LogoComponent from '../../components/header/LogoComponent'
import noImage from '../../assets/images/menu/600x400.png'
import placeholderImage from '../../assets/images/menu/540x360.png'
import Slider from 'react-slick'
import SpecialDayEffect from '../../components/celebrations/SpecialDayEffect'
import useQueryHook from '../../hooks/useQueryHook'
import WelcomeSkeleton from './WelcomeSkeleton'
import FontSlider from '../../components/header/FontSlider'
import { checkFestivalEffects } from '../../components/celebrations/helpers'
import { initialAboutUsFormData } from '../../helpers/aboutUsHelper'
import useEmblaCarousel from 'embla-carousel-react'
import { NextButton, PrevButton, usePrevNextButtons } from '../../components/f&b_menu/guest/EmblaCarouselArrowButton'

let intervalId: any

function WelcomePage() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const location = useLocation()
  const [menuItemsList, setMenuList] = useState<Menu[]>([])
  const [searchParams] = useSearchParams()
  const { trackEvent } = useTrackEvent()
  // CONTEXT VARIABLES
  const { setGuestFnbGlobalSettings } = useContext(CommonCtx)
  // STATE VARIABLES
  const [welcomeText, setWelcomeText] = useState('')
  const [aboutInfo, setAboutInfo] = useState<AboutUsConfig>(initialAboutUsFormData)
  const [welcomeImage, setWelcomeImage] = useState<UploadedWelcomeImage>(initialWelcomeImageData)

  const brandingInfo = queryClient.getQueryData<BrandingData>(BRANDING_INFO) as BrandingData

  const [emblaRef, emblaApi] = useEmblaCarousel({ dragFree: true })
  const {
		prevBtnDisabled,
		nextBtnDisabled,
		onPrevButtonClick,
		onNextButtonClick
	} = usePrevNextButtons(emblaApi)

  useEffect(() => {
    if (isQrScanPresent()) {
      // Google Analytics
      trackEvent({
        eventName: 'qr_scan',
        value: {
          qr_link_destination: '/welcome',
        }
      });
    }
  }, []) // eslint-disable-line

  // set welcome config data to variables
  const onGetWelcomeConfigSuccess = (res: AxiosResponse) => {
    const config: WelcomeConfig = res.data.data
    setWelcomeImage({ key: config.imageKey, signedUrl: config.signedUrl })
    setWelcomeText(config.welcomeRichTextData)
  }
  // fetch welcome screen config
  const welcomePageConfig = useQueryHook(
    PUBLIC_WELCOME_CONFIG,
    getPublicWelcomeScreenConfig,
    onGetWelcomeConfigSuccess
  )

  // fetch menu items
  useQueryHook(
    PUBLIC_MENU,
    () => getMenuItemsPublic(queryClient),
    (res) => {
      let data = res.data.data.list as Menu[]
      data = data.filter((menu) => menu.isActive)

      // Filter out menus that are not scheduled to be displayed
      let dataIncludingSchedule: Menu[] = data?.filter((menu) => {
        if (
          shouldShowMenuCard(menu.periodicSchedules) === true ||
          shouldShowMenuCard(menu.periodicSchedules) === NO_SCHEDULE
        ) {
          return menu
        }
      })
      setMenuList(dataIncludingSchedule)

      // update globalSettings
      const globalSetting = res.data.data.globalSettings as FnBGlobalSetting
      setGuestFnbGlobalSettings(globalSetting)

      // function to rerender the menu cards for showing and hiding scheduled menus
      intervalId = setInterval(() => {
        // Filter out menus that are not scheduled to be displayed
        let dataIncludingSchedule: Menu[] = data?.filter((menu) => {
          if (
            shouldShowMenuCard(menu.periodicSchedules) === true ||
            shouldShowMenuCard(menu.periodicSchedules) === NO_SCHEDULE
          ) {
            return menu
          }
        })
        setMenuList(dataIncludingSchedule)
        // update globalSettings
        const globalSetting = res.data.data.globalSettings as FnBGlobalSetting
        setGuestFnbGlobalSettings(globalSetting)
      }, MENU_RERENDER_INTERVAL)
    },
    undefined,
    true,
    false
  )

  // user tracking
  const userTracking = useQuery(USER_TRACKING, () => trackGuestUser, {
    enabled: false,
    retry: 0,
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
  })

  useEffect(() => {
    if (
      searchParams.get('server') !== null &&
      userTracking.data === undefined
    ) {
      const parser = new UAParser()
      const deviceId = getDeviceId()
      const xDeviceIdHeader = Buffer.from(
        JSON.stringify(parser.getResult())
      ).toString('base64')
      if (deviceId === null || deviceId === undefined) {
        setDeviceId()
      }
      queryClient.fetchQuery(USER_TRACKING, () =>
        trackGuestUser(queryClient, getDeviceId() as string, xDeviceIdHeader)
      )

      // cleanup
      // stop the setInterval function on page unmount
      return () => {
        clearInterval(intervalId)
      }
    }    
  }, []) // eslint-disable-line

  useEffect(() => {
    // getting menu data from react query cache
    const res = queryClient.getQueryData(PUBLIC_MENU) as AxiosResponse<any, any>
    const data = res?.data?.data?.list as Menu[]
    // Filter out menus that are not scheduled to be displayed
    let dataIncludingSchedule: Menu[] = data?.filter((menu) => {
      if (
        shouldShowMenuCard(menu.periodicSchedules) === true ||
        shouldShowMenuCard(menu.periodicSchedules) === NO_SCHEDULE
      ) {
        return menu
      }
    })

    if (dataIncludingSchedule) setMenuData(dataIncludingSchedule, setMenuList)

    // getting welcome config from react query cache
    const welcomeConfig = queryClient.getQueryData(
      PUBLIC_WELCOME_CONFIG
    ) as AxiosResponse<any, any>
    if (welcomeConfig) onGetWelcomeConfigSuccess(welcomeConfig)
  }, [queryClient])

  // about us page info
  const aboutUsConfig = useQueryHook(ABOUT_US_INFO, getPublicAboutUsScreenConfig, (res: AxiosResponse) => {
    const config: AboutUsConfig = res.data.data
    setAboutInfo(config)
  })

  useEffect(() => {
    if (location.pathname === routes.about_us) {
      aboutUsConfig.refetch()
    }
  }, [location.pathname])

  return (
    <div className='login d-flex justify-content-center align-items-center user-landing welcomePage'>
      <SpecialDayEffect />
      <div className='container'>
        <div className='row justify-content-center align-items-center m-0'>
          <div className='col-md-12 col-lg-9 col-xl-10 p-0'>
            <div className='login-box'>
              <div className='login-header welcome-login d-flex justify-content-sm-between justify-content-between p-2 ps-3 pe-3'>
                <div className='d-flex align-items-center justify-content-start'>
                  <div className='d-flex ham-container'>
                    <LogoComponent />
                  </div>
                </div>
                <div className='welcome-user-profile'>
                  <div className='me-3'>
                  <LanguageSwitcher />
                  </div>
                  <div className=''>
                    <FontSlider />
                  </div>
                 
                  <HeaderDropDown queryClient={queryClient} />
                </div>
              </div>
              <div className='login-body p-1'>
                <div className='container mt-3 mb-3 px-3'>
                  <div className='row mb-5'>
                    {welcomePageConfig.isLoading && (
                      <div className='col-md-6 welcome-img-left '>
                        <Skeleton height='27rem' width='100%'></Skeleton>
                      </div>
                    )}

                    {welcomePageConfig.isLoading && (
                      <div className='col-md-6'>
                        <WelcomeSkeleton />
                        <WelcomeSkeleton />
                        <WelcomeSkeleton />
                      </div>
                    )}

                    {location.pathname === routes.about_us ? (
                      <div className='col-md-12'>
                        <div className='d-flex justify-content-start align-items-center'>
                          <div
                            className="button btn flex-grow-0 me-2 back-btn back-btn-new"
                            data-testid="about-us-button"
                            onClick={() => navigate(-1)}
                          >
                            <i className='ri-arrow-left-line'></i>
                          </div>
                          <h3 className='m-0 ms-2'>
                            <JTranslation text={aboutInfo?.aboutUsTitle} type='html' />
                          </h3>
                        </div>

                        <div className='mt-3 col-md-12'>
                          {/* welcome image from server */}
                          {aboutUsConfig.isFetched &&
                            aboutInfo.signedUrl && (
                              <img
                                src={
                                  aboutInfo.signedUrl
                                    ? aboutInfo.signedUrl
                                    : noImage
                                }
                                className={`img-fluid res-img ${aboutInfo?.imagePlacement === 'left' ? 'image-left' : ''} ${aboutInfo?.imagePlacement === 'right' ? 'image-right' : ''}`}
                                alt=''
                              />
                            )}
                          <div className={`welcome-txt text-break ${aboutInfo?.imagePlacement === 'top' ? 'mt-3' : ''}`}>
                            {aboutUsConfig.isLoading && (
                              <>
                                <Skeleton width="5rem" className="mb-2"></Skeleton>
                                <Skeleton width="10rem" className="mb-2"></Skeleton>
                                <Skeleton height='15rem' className="mb-2"></Skeleton>
                              </>
                            )}
                            {aboutUsConfig.isFetched && (
                              <JTranslation text={unescapeHtml(aboutInfo?.aboutUsRichTextData)} type='html' />
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className='col-md-12'>
                        {/* welcome image from server */}
                        {welcomePageConfig.isFetched &&
                          welcomeImage.signedUrl && (
                            <img
                              src={
                                welcomeImage.signedUrl
                                  ? welcomeImage.signedUrl
                                  : noImage
                              }
                              className='img-fluid res-img image-left'
                              alt=''
                            />
                          )}
                        <div className='welcome-txt text-break'>
                          <JTranslation text={unescapeHtml(welcomeText)} type='html' />
                        </div>
                        {!welcomePageConfig.isLoading && (
                          <div className='d-flex justify-content-center align-items-center text-center'>
                            <button
                              className="btn btn-custom-primary py-2 px-4"
                              data-testid="about-us-button"
                              onClick={() => navigate(routes.about_us)}
                            >
                              {<JTranslation text="About Us" typeCase='capitalize' />}
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  {/* {location.pathname !== routes.about_us ? ( 
                  <div className='row mb-5 sticky-welcome-menu shadow-custom'>
                    <div className='col-lg-12'>
                      <div className='scroll-menu-container'>
                        <div className='nav-scroller'>
                          <nav className='nav-scroller-nav'>
                            <div className='nav-scroller-content-new main-menu'>
                              {menuItemsList.length ? (
                                <Slider {...welcomeSliderSettings}>
                                  {menuItemsList?.map((menu, index) => {
                                    return (
                                      <div
                                        key={menu.id}
                                        data-testid={'slider-item#' + menu.id}
                                        className='nav-scroller-item'
                                        onClick={() => {
                                          saveMenuSelection(
                                            queryClient,
                                            undefined
                                          )
                                          navigate(
                                            `${routes.menu_view}/${menu.urlSlug}${location.search}`,
                                            {
                                              state: {
                                                menuId: menu.urlSlug,
                                                selectionIndex: index,
                                              },
                                            }
                                          )
                                          
                                          if (
                                            brandingInfo?.enableCelebrationEffect &&
                                            [
                                              FESTIVAL_TEMPLATES.CHRISTMAS_NEWYEAR,
                                              FESTIVAL_TEMPLATES.VALENTINES_DAY,
                                            ].includes(brandingInfo?.activeCelebrationTemplate as string) &&
                                            brandingInfo?.showCelebrationTemplateOn?.includes('welcomePage')
                                          ) {
                                            playSound(index)
                                          }
                                        }}
                                      >
                                        <div className='menu-title'>
                                          <JTranslation
                                            text={menu.categoryName}
                                            typeCase='pascal'
                                          />
                                        </div>
                                        <div className='overlay'></div>
                                        <img
                                          src={
                                            menu.signedUrl || placeholderImage
                                          }
                                          alt='menu'
                                        />
                                        {checkFestivalEffects(brandingInfo, FESTIVAL_TEMPLATES.VALENTINES_DAY, location.pathname) && <HeartIcon index={index} />}
                                      </div>
                                    )
                                  })}
                                </Slider>
                              ) : (
                                <SliderSkeleton />
                              )}
                            </div>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div> ) : null } */}

                  {/* image-navigation using Embla Carousel */}
                  {location.pathname !== routes.about_us ? ( 
                  <div className='row mb-5 sticky-welcome-menu shadow-custom'>
                    <div className='col-lg-12'>
                      <div className='scroll-menu-container'>
                        <div className='nav-scroller'>
                          <nav className='nav-scroller-nav'>
                            <div className='nav-scroller-content-new main-menu'>
                              {menuItemsList.length ? (
                                <div className="embla">
                                  <div className="embla__viewport" ref={emblaRef}>
                                    <div className="embla__container">
                                        <>
                                          {menuItemsList?.map((menu, index) => {
                                            return (
                                              <div
                                                key={menu.id}
                                                data-testid={'slider-item#' + menu.id}
                                                className='nav-scroller-item'
                                                onClick={() => {
                                                  saveMenuSelection(
                                                    queryClient,
                                                    undefined
                                                  )
                                                  navigate(
                                                    `${routes.menu_view}/${menu.urlSlug}${location.search}`,
                                                    {
                                                      state: {
                                                        menuId: menu.urlSlug,
                                                        selectionIndex: index,
                                                      },
                                                    }
                                                  )
                                                  
                                                  if (
                                                    brandingInfo?.enableCelebrationEffect &&
                                                    [
                                                      FESTIVAL_TEMPLATES.CHRISTMAS_NEWYEAR,
                                                      FESTIVAL_TEMPLATES.VALENTINES_DAY,
                                                    ].includes(brandingInfo?.activeCelebrationTemplate as string) &&
                                                    brandingInfo?.showCelebrationTemplateOn?.includes('welcomePage')
                                                  ) {
                                                    playSound(index)
                                                  }
                                                }}
                                              >
                                                <div className='menu-title'>
                                                  <JTranslation
                                                    text={menu.categoryName}
                                                    typeCase='pascal'
                                                  />
                                                </div>
                                                <div className='overlay'></div>
                                                <img
                                                  src={
                                                    menu?.photoThumbnails || placeholderImage
                                                  }
                                                  onError={(event)=>{event.currentTarget.src = menu.signedUrl }}
                                                  alt='menu'
                                                />
                                                {checkFestivalEffects(brandingInfo, FESTIVAL_TEMPLATES.VALENTINES_DAY, location.pathname) && <HeartIcon index={index} />}
                                              </div>
                                            )
                                          })}
                                        </>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <SliderSkeleton />
                              )}
                              <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
                              <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
                            </div>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div> ) : null }

                  <div className='row d-flex justify-content-center mb-5'>
                    <div className='col-md-6 col-lg-6 col-xl-5'>
                      <div className='link-container text-center d-grid '>
                        <h5>  <JTranslation text={'Win a free dinner?'} typeCase='capitalize' /> </h5>
                        <button
                          type='button'
                          data-testid='take-survey'
                          className='btn btn-primary btn-lg btn-block btn-custom-primary'
                          onClick={() =>
                            navigate(`${routes.survey_guest}${location.search}`)
                          }
                        >
                          <JTranslation text={'Take Survey'} typeCase='capitalize' />{' '}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WelcomePage
