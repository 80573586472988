import { useContext, useEffect, useState } from 'react'
import './work_schedule.scss'
import { AxiosResponse } from 'axios'
import { Badge } from 'primereact/badge'
import { Button } from 'primereact/button'
import { checkStaffSchedule, cloneDraftFromPublished, copyEntireSchedule, CopySchedulePayloadType, createDraftSchedule, deleteExistingDraft, depOptionTemplate, depSelectionTemplate, DraftResponse, DropDown, fetchDraftedAndPublishedScheduleIds, fetchStaffsWithSchedulesAndShift, getGlobalStaffing, getScheduleId, getWeekRange, initialDataMinimumEmployeeCount, initialScheduleStatus, optionTemplate, optionTemplateWithTime, publishDraftedSchedule, Schedule, ScheduleData, ScheduleIdsResponse, ScheduleMetadata, selectionTemplate, Shifts, shiftSelectionTemplate, Staff, staffCountByDay, StartAndEndDates } from '../../helpers/workTimeScheduleHelper'
import { CommonCtx } from '../../context/CommonCtxProvider'
import { ConfirmPopup } from 'primereact/confirmpopup'
import { CREATE_DRAFT_SCHEDULE, ROLES_LIST, SCHEDULE_STATUS, SHIFT_CONFIG_LIST, STAFFING_REQUIREMENTS_LIST, STAFFS_WITH_SCHEDULES_AND_SHIFT } from '../../constants/queryKeys'
import { DATE_FORMAT, MOBILE_VIEW_BREAKPOINT, TAB_VIEW_BREAKPOINT, toastMessageInitialData } from '../../constants/constants'
import { DatePicker } from 'antd'
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown'
import { getRolesList } from '../../helpers/roleHelper'
import { getShiftConfigList } from '../../helpers/shiftConfigHelper'
import { isEmptyObject } from '../../helpers/utils'
import { JTranslation } from '../../helpers/jTranslate'
import { PUBLISH, WORK_TIME_SCHEDULE, WORK_TIME_SCHEDULE_HELP, PUBLISHED, DRAFT, CONFIRM_PUBLISH_SCHEDULE, CONFIRM_UPDATE_SCHEDULE, CLEAR_DRAFT, CONFIRM_CLEAR_DRAFT, ROLE, SHIFT, STAFF_SCHEDULE_DISCREPANCY, STAFF_COUNT, EDIT, AREA_OF_OPERATION, COPY_SCHEDULE, COPY_SCHEDULE_TO } from '../../constants/strings'
import { ToastMessageProps, JobRole, ShiftTemplate, PerDayCount, ShiftDropDown, ScheduleRoleDropDown, ScheduleDepartmentDropDown } from '../../constants/staticTypes'
import { useQueryClient } from 'react-query'
import { useWindowSize } from 'react-use'
import { v4 as uuidv4 } from 'uuid'
import CommonModal from '../../components/common_modal/CommonModal'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import Loader from '../../components/loader/Loader'
import MinimumEmployeeCountSideBar from '../../components/manage_work_schedule/MinimumEmployeeCountSideBar'
import MlxPopover from '../../components/common/MlxPopover'
import scheduleError from '../../assets/images/work_time_schedule_error.png'
import ShiftScheduler from '../../components/manage_work_schedule/ShiftScheduler'
import ShiftSchedulerMobileView from '../../components/manage_work_schedule/ShiftSchedulerMobileView'
import ShiftScheduleSideBar from '../../components/manage_work_schedule/ShiftScheduleSideBar'
import ToastAlert from '../../components/alert/ToastAlert'
import useMutationHook from '../../hooks/useMutationHook'
import useQueryHook from '../../hooks/useQueryHook'
import withSidebar from '../../hoc/withSidebar'
dayjs.extend(isSameOrBefore);

const ManageWorkSchedule = () => {
    const { showSideBar, setShowSideBar } = useContext(CommonCtx)
    const { width } = useWindowSize()
    const breakPoint = MOBILE_VIEW_BREAKPOINT
    const breakPointTab = TAB_VIEW_BREAKPOINT
    const queryClient = useQueryClient()
    const scheduleMutation = useMutationHook(queryClient, true)
    const defaultCopyToWeek = dayjs().add(1, 'week')

    const [selectedWeek, setSelectedWeek] = useState<dayjs.Dayjs>(dayjs())
    const [copyToWeek, setCopyToWeek] = useState<dayjs.Dayjs>(defaultCopyToWeek);
    const [selectedDateRange, setSelectedDateRange] = useState<StartAndEndDates>(getWeekRange(selectedWeek))
    const [selectedDay, setSelectedDay] = useState('')
    const [selectedDepartment, setSelectedDepartment] = useState<ScheduleDepartmentDropDown | null>(null)
    const [selectedRole, setSelectedRole] = useState<DropDown | null>(null)
    const [selectedEmployeeName, setSelectedEmployeeName] = useState('')
    const [staffDataWithSchedule, setStaffDataWithSchedule] = useState<Staff | null>(null)
    const [selectedShift, setSelectedShift] = useState<Shifts | null>(null)
    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
    const [shiftSideBar, setShiftSideBar] = useState(false)
    const [pageInitialLoad, setPageInitialLoad] = useState(true)
    const [departments, setDepartments] = useState<ScheduleDepartmentDropDown[]>([])
    const [roles, setRoles] = useState<ScheduleRoleDropDown[]>([])
    const [shifts, setShifts] = useState<ShiftDropDown[]>([])
    const [draftedScheduleId, setDraftedScheduleId] = useState('')
    const [publishedScheduleId, setPublishedScheduleId] = useState('')
    const [scheduleStatus, setScheduleStatus] = useState<Schedule>(initialScheduleStatus)
    const [scheduleData, setScheduleData] = useState<ScheduleIdsResponse | null>(null)
    const [staffsWithSchedule, setStaffsWithSchedule] = useState<ScheduleData | null>(null)
    const [scheduleMetadata, setScheduleMetadata] = useState<ScheduleMetadata | null>(null)
    const [staffingRequirement, setStaffingRequirement] = useState(initialDataMinimumEmployeeCount)
    const [scheduleId, setScheduleId] = useState('')
    const [isDateOlder, setIsDateOlder] = useState<boolean>(false)
    const [visible, setVisible] = useState({ edit: false, publish: false, clear: false })
    const [showCopyScheduleModal, setShowCopyScheduleModal] = useState(false)
    const [submitCounter, setSubmitCounter] = useState(0)
    const [isCountOk, setIsCountOk] = useState<boolean>(true)
    const [filteredRoles, setFilteredRoles] = useState<ScheduleRoleDropDown[]>([])
    const [dateChangeDataLoading, setDateChangeDataLoading] = useState<boolean>(false);
    const [schedulerOpenFlag, setSchedulerOpenFlag] = useState<string>(uuidv4()); // unique id to know the sidebar closing
    const [showPreview, setShowPreview] = useState(false)

    const onCopySuccess = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
        setShowCopyScheduleModal(false)
        setCopyToWeek(defaultCopyToWeek)
    }

    const disablePastWeeks = (currentDate: dayjs.Dayjs) => {
        // Disable all dates before the start of the current week
        return currentDate && currentDate.isBefore(dayjs().startOf('week'), 'day');
    };

    const CopyScheduleModalContent = (
        <>
            <div className='mb-4'>
                <p className='text-muted'><JTranslation typeCase="none" text="Easily duplicate the schedule from this week to the next!" /></p>

            </div>
            <div className='mb-3'>
                <div className='mb-1 '>
                    <span className='fw-bold me-2'>{<JTranslation typeCase="none" text={
                        `Copying schedule from`
                    } />}</span>
                    <span>
                        {<JTranslation typeCase="none" text={
                            `"${dayjs(selectedWeek).startOf('week').format(DATE_FORMAT)} - ${dayjs(selectedWeek)
                                .endOf('week')
                                .format(DATE_FORMAT)}"`
                        } />}
                    </span>
                    {/* {<JTranslation typeCase="none" text={
                    `Copying schedule from "${dayjs(selectedWeek).startOf('week').format(DATE_FORMAT)} - ${dayjs(selectedWeek)
                        .endOf('week')
                        .format(DATE_FORMAT)}"`
                } />} */}
                </div>
            </div>
            <div className='mb-4'>
                <div className='mb-1 fw-bold'>{<JTranslation typeCase="none" text={COPY_SCHEDULE_TO} />}</div>
                <DatePicker
                    onChange={(date) => {
                        setCopyToWeek(dayjs(date))
                    }}
                    picker="week"
                    style={{ width: '100%' }}
                    value={copyToWeek}
                    className="custom-schedule-calendar"
                    format={(value) =>
                        `${dayjs(value).startOf('week').format(DATE_FORMAT)} - ${dayjs(value)
                            .endOf('week')
                            .format(DATE_FORMAT)}`
                    }
                    allowClear={false}
                    disabledDate={disablePastWeeks}
                />
            </div>
            <p className=' small mb-0 text-danger'><i className="ri-information-line me-2"></i><JTranslation typeCase="none" text="Any drafted schedule for the upcoming week will be overwritten." /></p>
        </>
    )

    const accept = () => {
        setVisible({ publish: false, edit: false, clear: false });

        switch (scheduleStatus.actionStatus) {
            case 'draft':
                publishDraftedSchedule(scheduleMutation, { scheduleId, scheduleData }, onSuccess, onError);
                break;
            case 'published':
                cloneDraftFromPublished(scheduleMutation, { scheduleId }, onSuccessWithoutMessage, onError);
                break;
            case 'clear-draft':
                deleteExistingDraft(scheduleMutation, { scheduleId: scheduleId }, onSuccessWithoutMessage, onError);
                break;
            default:
                // NO DEFAULT
                break;
        }
    };

    const copyScheduleApiCall = () => {
        // create a payload with from and to week date
        const payload: CopySchedulePayloadType = {
            scheduleId: scheduleId,
            startDate: dayjs(copyToWeek).startOf('week').format("YYYY-MM-DD"),
            endDate: dayjs(copyToWeek).endOf('week').format("YYYY-MM-DD")
        }
        // API call
        copyEntireSchedule(scheduleMutation, payload, onCopySuccess, onError)
    }

    const isTodayWithinSelectedWeek = () => {
        const today = dayjs().startOf('day')
        const endOfWeek = selectedWeek.endOf('week')
        today.isSameOrBefore(endOfWeek) ? setIsDateOlder(false) : setIsDateOlder(true)
    }

    const reloadPageData = () => {
        setScheduleData(null)
        setScheduleId("")
        setDraftedScheduleId("")
        setPublishedScheduleId("")
        scheduleDataQuery.refetch()
    }

    const resetPageFilters = () => {
        setSelectedDepartment(null)
        setSelectedRole(null)
        setSelectedShift(null)
    }

    const onSuccessOfStaffsWithSchedule = (res: AxiosResponse<any, any>) => {
        const staffsWithSchedule: ScheduleData = res.data.data;
        const { schedule } = staffsWithSchedule;

        const sortStaffByPreferredName = (staffsWithSchedule: ScheduleData) => {
            // Separate staffs into two groups: with shifts and without shifts
            const staffsWithShifts = staffsWithSchedule.staffs.filter(staff => Object.keys(staff.shifts).length > 0);
            const staffsWithoutShifts = staffsWithSchedule.staffs.filter(staff => Object.keys(staff.shifts).length === 0);

            // Sort each group by preferredName
            staffsWithShifts.sort((a, b) => a.preferredName.localeCompare(b.preferredName));
            staffsWithoutShifts.sort((a, b) => a.preferredName.localeCompare(b.preferredName));

            // Combine the sorted arrays, with staff with shifts first
            const sortedStaffs = [...staffsWithShifts, ...staffsWithoutShifts];

            // Update the staffs in the original object
            return { ...staffsWithSchedule, staffs: sortedStaffs };
        };

        const sortedStaffsWithSchedule = sortStaffByPreferredName(staffsWithSchedule);
        setStaffsWithSchedule(sortedStaffsWithSchedule);
        setScheduleMetadata(schedule?.scheduleMetadata ?? {} as ScheduleMetadata);
        setPageInitialLoad(false);
        setDateChangeDataLoading(false);
    };

    const onErrorOfStaffsWithSchedule = () => {
        setDateChangeDataLoading(false);
    }

    const onSuccessOfScheduleStatus = (res: AxiosResponse<any, any>) => {
        const scheduleData: ScheduleIdsResponse = res.data.data
        setScheduleData(scheduleData)
        // Destructure draft and published from scheduleData
        const { draft, published } = scheduleData

        // Check if draft and published schedules are empty
        const isDraftEmpty = isEmptyObject(draft)
        const isPublishedEmpty = isEmptyObject(published)

        // If draft is not empty, save the draft's id using saveDraftedScheduleId
        if (!isDraftEmpty) saveDraftedScheduleId(draft.id)

        // If published is not empty, save the published's id using savePublishedScheduleId
        if (!isPublishedEmpty) savePublishedScheduleId(published.id)
    }

    const onSuccessOfDraftCreated = (res: AxiosResponse<any, any>) => {
        const draftData: DraftResponse = res.data.data
        saveDraftedScheduleId(draftData.id)
    }

    const onSuccessOfGlobalStaffing = (res: AxiosResponse<any, any>) => {
        const staffingData: PerDayCount[] = res.data.data.lists
        setStaffingRequirement({ availability: staffingData })
    }

    const onSuccess = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
        reloadPageData()
    }

    const onSuccessWithoutMessage = (message: string, variant: string) => {
        reloadPageData()
    }

    const onError = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
    }

    const onRoleListSuccess = (res: AxiosResponse<any, any>) => {
        let data: JobRole[] | [] = res.data?.data.lists
        let departments: ScheduleDepartmentDropDown[] = []
        let roleDetails: ScheduleRoleDropDown[] = []

        data.forEach((item) => {
            const { id, jobRoleName, jobRoleDepartment, isAvailableForSchedule } = item

            if (!isAvailableForSchedule) return;

            if (jobRoleDepartment.departmentName) {
                departments.push({
                    departmentId: jobRoleDepartment.id,
                    depName: jobRoleDepartment.departmentName ?? "",
                })
            }

            roleDetails.push({
                code: id,
                name: jobRoleName,
                roleDepartmentName: jobRoleDepartment.departmentName ?? "",
                roleDepartmentId: jobRoleDepartment.id ?? "",
            })

            roleDetails.sort((a, b) => a.name.localeCompare(b.name));
        })

        // Use Set to remove duplicates based on the department ID
        const uniqueDepartments = Array.from(
            new Set(departments.map(dep => dep.departmentId))
        ).map(code => departments.find(dep => dep.departmentId === code)!);

        setRoles(roleDetails);
        setDepartments(uniqueDepartments);
    }

    const onShiftConfigSuccess = (res: AxiosResponse<any, any>) => {
        const data: ShiftTemplate[] = res.data?.data.lists
        let shiftDetails: ShiftDropDown[] = []
        data.forEach((item) => {
            const { shiftName, startTime, endTime } = item
            shiftDetails.push({
                code: shiftName,
                name: shiftName,
                startTime: startTime,
                endTime: endTime
            })
        })
        shiftDetails.push({ code: 'Custom Shift', name: 'Custom Shift', startTime: '00:00:00', endTime: '00:00:00' });
        // Sort shiftDetails by startTime
        shiftDetails.sort((a, b) => a.startTime.localeCompare(b.startTime));
        setShifts(shiftDetails)
    }

    const resetScheduleData = () => {
        const res = queryClient.getQueryData(STAFFS_WITH_SCHEDULES_AND_SHIFT) as AxiosResponse<any, any>
        if (res) onSuccessOfStaffsWithSchedule(res)
    }
    const filterStaff = ({ staffArray, roleId, shiftName, departmentId }: { staffArray: Staff[]; roleId?: string; shiftName?: string; departmentId?: string }) => {
        return staffArray.filter(staff => {
            // Check if the staff has the matching job role
            const hasMatchingRole = roleId
                ? staff.jobRoles.some(jobRole => jobRole.jobRoleId === roleId)
                : true;

            // Check if the staff has the matching shift name
            const hasMatchingShift = shiftName
                ? Object.values(staff.shifts).flat().some(shift => shift.assignedShifts.shiftName === shiftName)
                : true;

            // Check if the staff has the matching department in their job role or shift
            const hasMatchingDepartment = departmentId
                ? staff.jobRoles?.some(jobRole => jobRole?.jobRole?.jobRoleDepartmentId === departmentId)
                : true;
            // Return staff if all conditions match
            return hasMatchingRole && hasMatchingShift && hasMatchingDepartment;
        });
    };

    const saveDraftedScheduleId = (id: string) => setDraftedScheduleId(id)
    const savePublishedScheduleId = (id: string) => setPublishedScheduleId(id)
    const saveEmployeeName = (name: string) => setSelectedEmployeeName(name)

    // fetch shift list (api call)
    useQueryHook(SHIFT_CONFIG_LIST, () => getShiftConfigList(), onShiftConfigSuccess)
    // fetch role list (api call)
    useQueryHook(ROLES_LIST, () => getRolesList({ limit: 500 }), onRoleListSuccess)
    // api call to fetch staffing requirements
    const staffingQuery = useQueryHook(STAFFING_REQUIREMENTS_LIST, () => getGlobalStaffing(), onSuccessOfGlobalStaffing)
    // schedule data main QUERY-1
    const scheduleDataQuery = useQueryHook(SCHEDULE_STATUS, () => fetchDraftedAndPublishedScheduleIds(selectedDateRange), onSuccessOfScheduleStatus)
    // If both the draft and published IDs are empty, call the Create Draft API (api call)
    const isDraftEmpty = scheduleData?.draft && isEmptyObject(scheduleData.draft); // Check if the draft schedule ID is empty
    const isPublishedEmpty = scheduleData?.published && isEmptyObject(scheduleData.published); // Check if the published schedule ID is empty
    const createDraft = scheduleData && isDraftEmpty && isPublishedEmpty; // Determine if Create Draft API should be called
    //  schedule data dependent QUERY-2
    const draftDataQuery = useQueryHook(CREATE_DRAFT_SCHEDULE, () => createDraftSchedule(selectedDateRange), onSuccessOfDraftCreated, undefined, !!createDraft)

    // Determine which schedule ID to use
    useEffect(() => {
        const scheduleId = getScheduleId(draftedScheduleId, publishedScheduleId);
        setScheduleId(scheduleId)
    }, [draftedScheduleId, publishedScheduleId])

    //  schedule data dependent QUERY-3
    const staffsWithScheduleQuery = useQueryHook(STAFFS_WITH_SCHEDULES_AND_SHIFT, () => fetchStaffsWithSchedulesAndShift({ scheduleId }), onSuccessOfStaffsWithSchedule, onErrorOfStaffsWithSchedule, !!scheduleId)

    // assigning API data to state variables
    useEffect(() => {
        if (staffsWithSchedule) {
            const { schedule } = staffsWithSchedule;
            setScheduleStatus(schedule);
        }
    }, [staffsWithSchedule]); // Run effect when staffsWithSchedule data changes

    // filter schedules
    useEffect(() => {
        if (staffsWithSchedule && (selectedRole?.code || selectedShift?.code || selectedDepartment?.departmentId)) {
            const { staffs } = staffsWithSchedule;
            const filterStaffByRole = filterStaff({ staffArray: staffs, roleId: selectedRole?.code, shiftName: selectedShift?.code, departmentId: selectedDepartment?.departmentId });
            setStaffsWithSchedule({ ...staffsWithSchedule, staffs: filterStaffByRole });
        } else if (!selectedRole?.code && !selectedShift?.code && !selectedDepartment?.departmentId) {
            resetScheduleData();
        }
    }, [selectedRole, selectedShift, selectedDepartment, submitCounter]); // eslint-disable-line


    // refetch the staff schedule api once the selected date has changed 
    useEffect(() => {
        reloadPageData();
        resetPageFilters();
    }, [selectedDateRange]) // eslint-disable-line

    // Calculate the date range for the selected week
    useEffect(() => {
        const dateRange = getWeekRange(selectedWeek)
        setSelectedDateRange(dateRange)
        isTodayWithinSelectedWeek()
        setDateChangeDataLoading(true)
    }, [selectedWeek]) // eslint-disable-line

    // check expected vs current staff count
    const checkStaffCount = () => {
        const staffCount = checkStaffSchedule(staffCountByDay)
        setIsCountOk(staffCount)
    }
    // used to rerun the filterStaff function
    const submitCount = () => {
        setSubmitCounter((current) => current + 1)
    }

    // Initialize filteredRoles when component mounts or roles change
    useEffect(() => {
        if (selectedDepartment) {
            // Filter roles based on the selected department
            const filtered = roles.filter(
                (role) => role.roleDepartmentId === selectedDepartment.departmentId
            );
            setFilteredRoles(filtered);
        } else {
            // If no department is selected, set all roles
            setFilteredRoles(roles);
        }
    }, [selectedDepartment, roles]);


    const handleDepartmentChange = (e: DropdownChangeEvent) => {
        resetScheduleData();
        setSelectedDepartment(e.value);

        // Filter roles based on selected department
        const departmentId = e.value?.departmentId;
        const filtered = roles.filter(role => role.roleDepartmentId === departmentId);
        setFilteredRoles(filtered);
    };

    const FiltersForWeb = (
        <>
            <Dropdown
                value={selectedDepartment}
                onChange={handleDepartmentChange}
                options={departments}
                optionLabel="name"
                placeholder={AREA_OF_OPERATION}
                filter
                filterBy="depName"
                showClear
                disabled={!departments.length}
                valueTemplate={depSelectionTemplate}
                itemTemplate={depOptionTemplate}
                className="w-full md:w-14rem"
            />
            <Dropdown
                value={selectedRole}
                onChange={(e: DropdownChangeEvent) => {
                    resetScheduleData()
                    setSelectedRole(e.value)
                }}
                options={filteredRoles}
                optionLabel="name"
                placeholder={ROLE}
                filter
                disabled={!filteredRoles.length}
                showClear
                valueTemplate={selectionTemplate}
                itemTemplate={optionTemplate}
                className="w-full md:w-14rem"
            />
            <Dropdown
                value={selectedShift}
                onChange={(e: DropdownChangeEvent) => {
                    resetScheduleData()
                    setSelectedShift(e.value)
                }}
                options={shifts}
                optionLabel="name"
                placeholder={SHIFT}
                filter
                filterBy='name'
                disabled={!shifts.length}
                showClear
                valueTemplate={shiftSelectionTemplate}
                itemTemplate={optionTemplateWithTime}
                className="w-full md:w-14rem"
            />
        </>
    )

    const FiltersForMobile = (
        <>
            <Dropdown
                value={selectedDepartment}
                onChange={(e: DropdownChangeEvent) => {
                    resetScheduleData()
                    setSelectedDepartment(e.value)
                }}
                options={departments}
                optionLabel="name"
                filter
                showClear
                disabled={!departments.length}
                valueTemplate={depSelectionTemplate}
                itemTemplate={depOptionTemplate}
                className="filter-button-mobile btn-prime-primary-outline"
                dropdownIcon={() => <i className="ri-map-pin-line"></i>}
            />
            <Dropdown
                value={selectedRole}
                onChange={handleDepartmentChange}
                options={filteredRoles}
                optionLabel="name"
                filter
                disabled={!filteredRoles.length}
                showClear
                valueTemplate={selectionTemplate}
                itemTemplate={optionTemplate}
                className="filter-button-mobile btn-prime-primary-outline"
                dropdownIcon={() => <i className="ri-user-2-line"></i>}
            />
            <Dropdown
                value={selectedShift}
                onChange={(e: DropdownChangeEvent) => {
                    resetScheduleData()
                    setSelectedShift(e.value)
                }}
                options={shifts}
                optionLabel="name"
                filter
                disabled={!shifts.length}
                showClear
                valueTemplate={shiftSelectionTemplate}
                itemTemplate={optionTemplateWithTime}
                className="filter-button-mobile btn-prime-primary-outline"
                dropdownIcon={() => <i className="ri-calendar-schedule-line"></i>}
            />
        </>
    )

    const PublishOrEditContent = (
        <div>
            {
                (scheduleStatus.actionStatus === 'draft' || scheduleStatus.actionStatus === 'clear-draft')
                    ? !isCountOk ? `${STAFF_SCHEDULE_DISCREPANCY} ${CONFIRM_PUBLISH_SCHEDULE}` : CONFIRM_PUBLISH_SCHEDULE
                    : CONFIRM_UPDATE_SCHEDULE
            }
        </div>
    )

    const ClearContent = <div>{CONFIRM_CLEAR_DRAFT}</div>;

    // loading screen
    if (
        pageInitialLoad ||
        staffsWithScheduleQuery.isLoading ||
        dateChangeDataLoading
    ) {
        return <Loader />;
    }

    // error screen
    if (scheduleDataQuery.status === "error" || draftDataQuery.status === "error" || staffsWithScheduleQuery.status === "error") {
        return (

            <div className="text-center d-flex flex-column align-items-center justify-content-center">
                <img src={scheduleError} alt="" className="img-fluid mb-3" />
                <h2><JTranslation typeCase="pascal" text={'Oops!!'} /></h2>
                <h5 className="mt-2 mb-3">
                    <div className='mb-1 error-text-schedule'><JTranslation typeCase="pascal" text={"Something went wrong.We're sorry, but an error occurred while loading this page."} /></div>
                    <div className='error-text-schedule'><JTranslation typeCase="pascal" text={'Please try refreshing the page or come back later.'} /></div>
                </h5>
                <div className='mt-2 mb-2'>
                    <button
                        className="btn btn-custom-primary d-flex">
                        <i className="ri-restart-line me-1"></i>
                        <span className="btn-text"><JTranslation typeCase="pascal" text={'Refresh Page'} /></span>
                    </button>
                </div>
            </div>
        )
    }

    if (staffsWithScheduleQuery.status === "success" && staffingQuery.status === "success") {
        return (
            <>
                <CommonModal
                    show={(visible.publish || visible.edit || visible.clear)}
                    onHide={() => setVisible({ publish: false, edit: false, clear: false })}
                    modalContent={visible.clear ? ClearContent : PublishOrEditContent}
                    title="Review and Confirm Changes"
                    callback={accept}
                />

                <CommonModal
                    show={showCopyScheduleModal}
                    onHide={() => {
                        setShowCopyScheduleModal(false)
                        setCopyToWeek(defaultCopyToWeek)
                    }}
                    modalContent={CopyScheduleModalContent}
                    title={COPY_SCHEDULE}
                    callback={copyScheduleApiCall}
                    proceedButtonName={COPY_SCHEDULE}
                />

                <div className="page-title d-flex mb-3 align-items-center">
                    <div className="flex-grow-1 d-flex">
                        <span className="d-flex">
                            <h5 className="d-flex mb-0 align-items-center">
                                <JTranslation typeCase="pascal" text={WORK_TIME_SCHEDULE} />
                                <MlxPopover data={{ title: WORK_TIME_SCHEDULE, body: WORK_TIME_SCHEDULE_HELP }} />
                            </h5>
                        </span>
                        <span className="d-flex align-items-center">
                            {(scheduleStatus.actionStatus === 'draft' || scheduleStatus.actionStatus === 'clear-draft')
                                ? <Badge
                                    value={<JTranslation typeCase="pascal" text={DRAFT} />}
                                    severity="danger"
                                    size={'large'}
                                    className="schedule-title-badge ms-2 text-nowrap"
                                />
                                : <Badge
                                    value={<JTranslation typeCase="pascal" text={PUBLISHED} />}
                                    severity="success"
                                    size={'large'}
                                    className="schedule-title-badge ms-2 text-nowrap"
                                />}
                        </span>
                    </div>
                    <div className="flex-grow-0 align-items-center">
                        {/* Fullscreen preview */}
                        {width >= breakPoint && (
                            <Button
                                icon="pi pi-window-maximize"
                                className=" p-button-rounded p-button-primary p-button-text fs-4 color-primary-custom"
                                title="Fullscreen"
                                onClick={() => {
                                    setShowPreview(true)
                                }}
                            />
                        )}

                        <Button
                            icon="ri-settings-4-line "
                            className=" p-button-rounded p-button-primary p-button-text fs-4 color-primary-custom"
                            title={STAFF_COUNT}
                            data-testid="global-config"
                            onClick={() => {
                                setShowSideBar(!showSideBar)
                            }}
                        />
                    </div>
                </div>
                {/* TOAST MESSAGE COMPONENT */}
                <ToastAlert
                    show={toastMessage.show}
                    onClose={() => setToastMessage(toastMessageInitialData)}
                    message={toastMessage.message}
                    variant={toastMessage.variant}
                />

                {/*SIDE BARS  */}
                <MinimumEmployeeCountSideBar setToastMessage={setToastMessage} />
                <ShiftScheduleSideBar
                    shiftSideBar={shiftSideBar}
                    setShiftSideBar={setShiftSideBar}
                    setToastMessage={setToastMessage}
                    selectedWeek={selectedWeek}
                    selectedEmployeeName={selectedEmployeeName}
                    setSelectedEmployeeName={setSelectedEmployeeName}
                    rolesList={roles}
                    departments={departments}
                    shifts={shifts}
                    scheduleId={scheduleId}
                    staffDataWithSchedule={staffDataWithSchedule}
                    selectedDay={selectedDay}
                    staffsWithSchedule={staffsWithSchedule}
                    submitCount={submitCount}
                    schedulerOpenFlag={schedulerOpenFlag}
                    setSchedulerOpenFlag={setSchedulerOpenFlag}
                    setShifts={setShifts}
                />
                <div className={`col-md-12 col-lg-12 mb-3 h-100 ${showPreview ? 'mlx-fullscreen' : ''}`}>
                    <div className="card h-100 ">
                        <div className="card-header flex-grow-0 work-schedule">
                            <div className="d-flex  align-items-center">
                                <div className="flex-grow-1">
                                    {width >= breakPoint && (
                                        <Button
                                            hidden={!showPreview}
                                            // size="large"
                                            // raised
                                            rounded
                                            outlined
                                            icon="pi pi-window-minimize"
                                            className="p-button-primary color-primary-custom"
                                            title="Close Preview"
                                            onClick={() => {
                                                setShowPreview(false)
                                            }}
                                        />
                                    )}
                                </div>
                                <div className="flex-grow-0 me-2 ms-1 gap-1 d-flex align-items-center">
                                    {/* switch web and mobile components */}
                                    {width >= breakPointTab ? FiltersForWeb : FiltersForMobile}

                                    <span>
                                        <DatePicker
                                            onChange={(date) => {
                                                setSelectedWeek(dayjs(date))
                                            }}
                                            picker="week"
                                            value={selectedWeek}
                                            className="custom-schedule-calendar"
                                            format={(value) =>
                                                `${dayjs(value).startOf('week').format(DATE_FORMAT)} - ${dayjs(value)
                                                    .endOf('week')
                                                    .format(DATE_FORMAT)}`
                                            }
                                            allowClear={false}
                                        />
                                    </span>
                                    {(scheduleStatus.actionStatus === 'draft' || scheduleStatus.actionStatus === 'clear-draft')
                                        ? <Button
                                            tooltip={CLEAR_DRAFT}
                                            severity="secondary"
                                            className="text-nowrap prime-icon-only-btn rounded btn-prime-primary-outline d-flex justify-content-center"
                                            tooltipOptions={{ position: 'bottom' }}
                                            disabled={staffsWithScheduleQuery.isFetching || scheduleDataQuery.isFetching || draftDataQuery.isFetching || isDateOlder}
                                            onClick={() => {
                                                // preventing accidental draft publish
                                                scheduleStatus.actionStatus = 'clear-draft'
                                                setVisible({ edit: false, publish: false, clear: true })
                                            }}
                                            icon={<i className="ri-close-line me-1"></i>}
                                        >
                                            <span className="btn-text overflow-ellipsis">
                                                <JTranslation typeCase="pascal" text={CLEAR_DRAFT} />
                                            </span>
                                        </Button>
                                        : ""
                                    }
                                    <Button
                                        tooltip={EDIT}
                                        className="text-nowrap prime-icon-only-btn rounded btn-prime-primary-outline d-flex justify-content-center"
                                        tooltipOptions={{ position: 'bottom' }}
                                        disabled={scheduleStatus.actionStatus === 'draft' || scheduleStatus.actionStatus === 'clear-draft' || scheduleMutation.isLoading || isDateOlder}
                                        onClick={() => setVisible({ edit: true, publish: false, clear: false })}
                                        icon={<i className="ri-pencil-line me-1"></i>}
                                        outlined
                                    >
                                        <span className="btn-text overflow-ellipsis">
                                            <JTranslation typeCase="pascal" text={EDIT} />
                                        </span>
                                    </Button>
                                    <Button
                                        tooltip={(scheduleStatus.actionStatus === 'draft' || scheduleStatus.actionStatus === 'clear-draft') ? PUBLISH : PUBLISHED}
                                        className="publish-button m-1 text-nowrap prime-icon-only-btn rounded"
                                        tooltipOptions={{ position: 'bottom' }}
                                        disabled={scheduleStatus.actionStatus === 'published' || scheduleMutation.isLoading || isDateOlder}
                                        onClick={() => {
                                            checkStaffCount()
                                            setVisible({ edit: true, publish: true, clear: false })
                                        }}
                                        icon={<i className="ri-send-plane-2-line me-1"></i>}
                                    >
                                        <span className="btn-text overflow-ellipsis">
                                            <JTranslation typeCase="pascal" text={(scheduleStatus.actionStatus === 'draft' || scheduleStatus.actionStatus === 'clear-draft') ? PUBLISH : PUBLISHED} />
                                        </span>
                                    </Button>
                                    <Button
                                        tooltip={COPY_SCHEDULE}
                                        severity="secondary"
                                        //className="text-nowrap prime-icon-only-btn rounded btn-prime-primary-outline d-flex justify-content-center"
                                        className='rounded'
                                        tooltipOptions={{ position: 'bottom' }}
                                        disabled={scheduleMutation.isLoading}
                                        onClick={() => setShowCopyScheduleModal(!showCopyScheduleModal)}
                                        //icon={<i className="ri-file-copy-line me-1"></i>}
                                        icon="pi pi-copy"
                                        outlined
                                    >
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body flex-grow-1 overflow-auto">
                            <ConfirmPopup />
                            <div className="survey-section h-100">
                                {/* switch web and mobile components */}
                                {width >= breakPoint ? (
                                    <ShiftScheduler
                                        shiftSideBar={shiftSideBar}
                                        setShiftSideBar={setShiftSideBar}
                                        saveEmployeeName={saveEmployeeName}
                                        selectedWeek={selectedWeek}
                                        staffsWithSchedule={staffsWithSchedule}
                                        staffingRequirement={staffingRequirement.availability}
                                        scheduleId={scheduleId}
                                        setToastMessage={setToastMessage}
                                        reloadPageData={reloadPageData}
                                        setData={setStaffDataWithSchedule}
                                        setSelectedDay={setSelectedDay}
                                        scheduleMetadata={scheduleMetadata}
                                    />
                                ) : (
                                    <ShiftSchedulerMobileView
                                        shiftSideBar={shiftSideBar}
                                        setShiftSideBar={setShiftSideBar}
                                        saveEmployeeName={saveEmployeeName}
                                        selectedWeek={selectedWeek}
                                        staffsWithSchedule={staffsWithSchedule}
                                        staffingRequirement={staffingRequirement.availability}
                                        scheduleId={scheduleId}
                                        setToastMessage={setToastMessage}
                                        reloadPageData={reloadPageData}
                                        setData={setStaffDataWithSchedule}
                                        setSelectedDay={setSelectedDay}
                                        scheduleMetadata={scheduleMetadata}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

export default withSidebar(ManageWorkSchedule)

