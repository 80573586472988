import dayjs from 'dayjs'
import { FaPlus, FaEdit } from 'react-icons/fa'
import { AvailabilityFormData, AvailabilityTimeFormData, AvailabilityWeekFormData } from '../../constants/staticTypes'
import { EDIT, ADD } from '../../constants/strings'
import { FormikProps } from 'formik'
import { isTimeRangeConflict } from '../../helpers/availabilityHelper'
import { JTranslation } from '../../helpers/jTranslate'
import MlxTooltip from '../common/MlxTooltip'
import MlxTimePicker from '../mlx_time_picker/MlxTimePicker'
import { useContext, useEffect, useState } from 'react'
import { CommonCtx } from '../../context/CommonCtxProvider'

type Props = {
	weekdayIndex: number
	editTimeIndex: number | null
	availabilityData: AvailabilityFormData
	availabilityWeekData: AvailabilityWeekFormData
	setEditTimeIndex: React.Dispatch<React.SetStateAction<number | null>>
	formikProps: FormikProps<AvailabilityFormData>
}

function AvailabilityTimeForm({
	weekdayIndex,
	editTimeIndex,
	setEditTimeIndex,
	availabilityData,
	availabilityWeekData,
	formikProps,
}: Readonly<Props>) {
	const { showSideBar } = useContext(CommonCtx)

	const [fromTime, setFromTime] = useState<dayjs.Dayjs | null>(null)
	const [toTime, setToTime] = useState<dayjs.Dayjs | null>(null)

	const handleAddTime = async (currentDayIndex: number | null) => {
		if (currentDayIndex !== null && fromTime && toTime && fromTime.isBefore(toTime)) {
			const newTimeRange: AvailabilityTimeFormData = {
				from: fromTime,
				to: toTime,
			}
			if (!isTimeRangeConflict(newTimeRange, availabilityWeekData.availableTimes)) {
				formikProps.setFieldValue(`weekdays.${weekdayIndex}.availableTimes`, [
					...availabilityWeekData.availableTimes,
					newTimeRange,
				])
				setFromTime(null)
				setToTime(null)
			} else {
				const weekdaysErrors = []
				weekdaysErrors[weekdayIndex] = {
					availableTimes:
						'Time range conflicts with an existing range. Please choose a different time range.',
				}
				formikProps.setErrors({ ...formikProps.errors, weekdays: weekdaysErrors })
			}
		}
	}

	const handleUpdateTime = (currentDayIndex: number | null) => {
		if (currentDayIndex !== null && editTimeIndex !== null && fromTime && toTime && fromTime.isBefore(toTime)) {
			const updatedTimesData = availabilityWeekData.availableTimes
			updatedTimesData[editTimeIndex] = {
				from: fromTime,
				to: toTime,
			}
			formikProps.setFieldValue(`weekdays.${weekdayIndex}.availableTimes`, updatedTimesData)
			setEditTimeIndex(null)
			setFromTime(null)
			setToTime(null)
		}
	}

	const handleEditTime = (currentDayIndex: number | null, index: number) => {
		if (currentDayIndex !== null) {
			setEditTimeIndex(index)
			setFromTime(dayjs(availabilityData.weekdays[currentDayIndex].availableTimes[index].from, 'hh:mm A'))
			setToTime(dayjs(availabilityData.weekdays[currentDayIndex].availableTimes[index].to, 'hh:mm A'))
		}
	}

	const handleDeleteTime = (currentDayIndex: number | null, index: number) => {
		if (currentDayIndex !== null) {
			const updatedTimesData = availabilityWeekData.availableTimes
			updatedTimesData.splice(index, 1)
			formikProps.setFieldValue(`weekdays.${weekdayIndex}.availableTimes`, updatedTimesData)
		}
	}

	useEffect(() => {
		if(!showSideBar) {
			setFromTime(null)
			setToTime(null)
		}
	}, [showSideBar])

	return (
		<>
			{!availabilityWeekData.isAllDay && availabilityWeekData.isAvailable && (
				<div className="mb-2 p-2 add-p-bg">
					<div className="row">
						<div className="col-md-5 mt-2">
							<div className="help-small">
								<label className="form-label m-0 d-flex">
									<JTranslation typeCase="pascal" text={"From"} /><span className="mandatory ">*</span>
								</label>
							</div>
							<MlxTimePicker
								className="form-control"
								data-test-id={`availability-weekdays-${weekdayIndex}-availabilityTimes-from-time`}
								placeholder="From"
								value={fromTime}
								onChange={(value) => {
									if (value) {
										setFromTime(value)
									}
								}}
							/>
						</div>
						<div className="col-md-5 mt-2">
							<div className="help-small">
								<label className="form-label m-0 d-flex">
									<JTranslation typeCase="pascal" text={"To"} /><span className="mandatory ">*</span>
								</label>
							</div>
							<MlxTimePicker
								className="form-control"
								data-test-id={`availability-weekdays-${weekdayIndex}-availabilityTimes-to-time`}
								placeholder="To"
								value={toTime}
								onChange={(value) => {
									if (value) {
										setToTime(value)
									}
								}}
							/>
						</div>
						<div className="col-md-2 mt-2 d-flex justify-content-end align-self-end">
							<button
								data-test-id={`availability-weekdays-${weekdayIndex}-${editTimeIndex !== null ? 'edit' : 'add'
									}-btn`}
								type="button"
								className="btn btn-custom-primary w-100"
								disabled={!fromTime || !toTime || !fromTime.isBefore(toTime)}
								onClick={(event) => {
									event.stopPropagation()
									if (fromTime && toTime && fromTime.isBefore(toTime)) {
										if (editTimeIndex !== null) {
											handleUpdateTime(weekdayIndex)
										} else {
											handleAddTime(weekdayIndex)
										}
									}
								}}
							>
								<FaPlus /> {editTimeIndex !== null
									? <JTranslation typeCase="pascal" text={EDIT} />
									: <JTranslation typeCase="pascal" text={ADD} />
								}
							</button>
						</div>
					</div>
				</div>
			)}

			{availabilityWeekData.availableTimes &&
				availabilityWeekData.availableTimes?.length > 0 &&
				availabilityWeekData.availableTimes.map((time, timeIndex) => (
					<div key={timeIndex}>
						<div className="mb-2 p-2 custom-group-box">
							<div className="row">
								<span className="col-md-6">
									<b>
										{time?.from?.format('hh:mm A')} <JTranslation typeCase="pascal" text={"to"} /> {time.to.format('hh:mm A')}
									</b>
								</span>
								<span className="col-md-4"><JTranslation typeCase="pascal" text={"Available"} /></span>
								<span className="col-md-2">
									<span className="d-flex justify-content-around align-items-center">
										<MlxTooltip target='.edit'>
											<FaEdit
												className="edit"
												data-pr-tooltip="Edit"
												data-pr-position="bottom"
												onClick={(e) => {
													e.stopPropagation()
													handleEditTime(weekdayIndex, timeIndex)
												}}
											/>
										</MlxTooltip>
										<i
											className="ri-delete-bin-line delete"
											onClick={(e) => {
												e.stopPropagation()
												handleDeleteTime(weekdayIndex, timeIndex)
											}}
										></i>
									</span>
								</span>
							</div>
						</div>
					</div>
				))}
		</>
	)
}

export default AvailabilityTimeForm
