import React, { useContext, useEffect, useState } from 'react'
import { ConfirmPopup } from 'primereact/confirmpopup'
import { Field, FieldProps, ErrorMessage, Formik, Form } from 'formik'
import { parseTimeForTimePicker } from '../../helpers/newsAndEventsHelper'
import { SHIFT_NAME, START_TIME, END_TIME } from '../../constants/strings'
import { ConfirmPopupType, ShiftFormData } from '../../constants/staticTypes'
import { initialValuesForConfirmPopup } from '../../helpers/timeOffHelper'
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate'
import { useWindowSize } from 'react-use'
import { shiftValidationSchema } from '../../helpers/shiftConfigHelper'
import { Button } from 'primereact/button'
import MlxTooltip from '../common/MlxTooltip'
import { FaEdit } from 'react-icons/fa'
import MlxTimePicker from '../mlx_time_picker/MlxTimePicker'

type ShiftConfigCardProps = {
	shift: ShiftFormData
	handleAddSubmit: (data: ShiftFormData) => void
	handleUpdateSubmit: (data: ShiftFormData) => void
	handleDeleteShift: (data: ShiftFormData) => void
	currentEditItem: string | null
	setCurrentEditItem: React.Dispatch<React.SetStateAction<string | null>>
}

const ShiftConfigCard: React.FC<ShiftConfigCardProps> = ({
	shift,
	handleAddSubmit,
	handleUpdateSubmit,
	handleDeleteShift,
	currentEditItem,
	setCurrentEditItem,
}) => {
	const translationContext = useContext(TranslationContext)
	const { targetLanguage } = translationContext
	const { width } = useWindowSize()

	const [shiftData] = useState<ShiftFormData>(shift)
	const [shiftFormData, setShiftFormData] = useState<ShiftFormData>(shift)
	const [requestInfo, setRequestInfo] = useState<ConfirmPopupType>(initialValuesForConfirmPopup)
	const [translationText, setTranslatedText] = useState<{
		startTime: string
		endTime: string
		confirmPopup: string
		shiftName: string
	}>({
		startTime: START_TIME,
		endTime: END_TIME,
		confirmPopup: 'Are you sure you want to proceed?',
		shiftName: SHIFT_NAME,
	})

	// Translate on load and language switch
	useEffect(() => {
		const fetchTranslation = async () => {
			try {
				const translations = await Promise.all([
					jTranslationText({ text: START_TIME, typeCase: 'pascal', translationContext }),
					jTranslationText({ text: END_TIME, typeCase: 'pascal', translationContext }),
					jTranslationText({
						text: 'Are you sure you want to proceed?',
						typeCase: 'capitalize',
						translationContext,
					}),
					jTranslationText({ text: SHIFT_NAME, typeCase: 'pascal', translationContext }),
				])

				setTranslatedText({
					startTime: translations[0] ?? START_TIME,
					endTime: translations[1] ?? END_TIME,
					confirmPopup: translations[2] ?? 'Are you sure you want to proceed?',
					shiftName: translations[3] ?? SHIFT_NAME,
				})
			} catch {
				setTranslatedText({
					startTime: START_TIME,
					endTime: END_TIME,
					confirmPopup: 'Are you sure you want to proceed?',
					shiftName: SHIFT_NAME,
				})
			}
		}
		fetchTranslation()
	}, [targetLanguage])

	return (
		<Formik
			enableReinitialize={true}
			initialValues={shiftFormData}
			validationSchema={shiftValidationSchema}
			onSubmit={(updatedData) => {
				if (updatedData.id.includes('new-item')) {
					handleAddSubmit(updatedData)
				} else {
					handleUpdateSubmit(updatedData)
				}
			}}
			validateOnChange={true}
		>
			{({ values, errors, touched }) => (
				<Form className="card mb-3">
					{/* accept request popup */}
					<ConfirmPopup
						target={document.getElementById(requestInfo.buttonName) ?? undefined}
						visible={requestInfo.visible}
						onHide={() =>
							setRequestInfo((prev) => {
								return {
									...prev,
									visible: false,
								}
							})
						}
						message={translationText.confirmPopup}
						icon="pi pi-exclamation-triangle"
						accept={() => handleDeleteShift(shiftFormData)}
						data-testid="confirm-popup"
					/>

					<div className="card-body py-0 d-flex p-0">
						<div className={`col-12 px-3 py-1 shift-card shadow-sm ${currentEditItem === shiftFormData.id ? 'edit-card' : ''}`}>
							<div className="row align-items-center">
								<div className="col-md-4 col-lg-6">
									<label className="form-label d-flex">
										{translationText.shiftName}
										<span className="mandatory ">*</span>
									</label>
									<Field
										style={
											errors?.shiftName && touched?.shiftName
												? { border: '1px solid red' }
												: undefined
										}
										name={`shiftName`}
										className="form-control"
										type="text"
										autoComplete="off"
										maxLength="250"
										placeholder={translationText.shiftName}
										id="shiftName"
										disabled={currentEditItem !== shiftFormData.id}
									/>
									<div className="formik-error w-max-content">
										&nbsp;
										<ErrorMessage
											name={`shiftName`}
											render={(error) => <JTranslation typeCase="pascal" text={error} />}
										/>
									</div>
								</div>

								<div className="col-sm-6 col-md-3 col-lg-2">
									<div className="help-small">
										<label className="form-label d-flex">
											{translationText.startTime}
											<span className="mandatory ">*</span>
										</label>
									</div>
									<Field name={`startTime`}>
										{({ field, form }: FieldProps) => (
											<MlxTimePicker
												className="form-control"
												id="startTime"
												placeholder={translationText.startTime}
												value={parseTimeForTimePicker({ formattedTime: field.value })}
												onChange={(value) => form.setFieldValue(field.name, value)}
												style={
													errors?.startTime && touched?.startTime
														? { border: '1px solid red' }
														: undefined
												}
												disabled={currentEditItem !== shiftFormData.id}
											/>
										)}
									</Field>
									<div className="formik-error w-max-content">
										&nbsp;
										<ErrorMessage
											name={`startTime`}
											render={(error) => <JTranslation typeCase="pascal" text={error} />}
										/>
									</div>
								</div>

								<div className="col-sm-6 col-md-3 col-lg-2">
									<div className="help-small">
										<label className="form-label d-flex">
											{translationText.endTime}
											<span className="mandatory ">*</span>
										</label>
									</div>
									<Field name={`endTime`}>
										{({ field, form }: FieldProps) => (
											<MlxTimePicker
												className="form-control"
												id="endTime"
												placeholder={translationText.endTime}
												value={parseTimeForTimePicker({ formattedTime: field.value })}
												onChange={(value) => form.setFieldValue(field.name, value)}
												style={
													errors?.endTime && touched?.endTime
														? { border: '1px solid red' }
														: undefined
												}
												disabled={currentEditItem !== shiftFormData.id}
											/>
										)}
									</Field>
									<div className="formik-error w-max-content">
										&nbsp;
										<ErrorMessage
											name={`endTime`}
											render={(error) => <JTranslation typeCase="pascal" text={error} />}
										/>
									</div>
								</div>

								<div
									className={`col-md-2 col-lg-2 d-flex justify-content-end gap-2 ${
										width <= 767 ? 'mt-3' : ''
									}`}
								>
									{currentEditItem === shiftFormData.id ? (
										<>
											<Button
												className="p-button-primary"
												icon="pi pi-save"
												rounded
												raised
												severity="warning"
												tooltip="Save"
												type="submit"
												data-pr-tooltip="Save"
												data-pr-position="bottom"
											/>
											<Button
												type="button"
												id={`delete-button-${values?.id}`}
												className=""
												icon="pi pi-times"
												rounded
												text
												raised
												severity="secondary"
												tooltip="Cancel"
												onClick={() => {
													const checkIfNewItem = values.id.includes('-new-item')
													if (checkIfNewItem) {
														if (values.startTime || values.endTime || values.shiftName) {
															setRequestInfo((prev) => {
																return {
																	...prev,
																	buttonName: String(`delete-button-${values?.id}`),
																	visible: true,
																}
															})
														} else {
															handleDeleteShift(shiftFormData)
														}
													} else {
														setShiftFormData((data) => {
															return {
																...data,
																shiftName: shiftData?.shiftName ?? '',
																startTime: shiftData?.startTime ?? null,
																endTime: shiftData?.endTime ?? null,
																id: shiftData?.id,
															}
														})
														setCurrentEditItem(null)
													}
												}}
												data-pr-tooltip="Cancel"
												data-pr-position="bottom"
											/>
										</>
									) : (
										<>
											<div className='rounded-background shadow-sm me-0'>
												<MlxTooltip target={'.edit-'+shiftFormData.id}>
													<FaEdit
														data-pr-tooltip={"Edit"}
														data-pr-position="bottom"
														className={
															'edit ms-2 ' + 
															('edit-'+shiftFormData.id)+
															(!!currentEditItem ? ' disabled' : '')
														}
														onClick={(event) => {
															event.preventDefault()
															if (currentEditItem) {
																return
															}
															setCurrentEditItem(shiftFormData.id)
														}}
													/>
												</MlxTooltip>
											</div>
											<Button
												type="button"
												id={`delete-button-${values?.id}`}
												className=""
												icon="pi pi-trash"
												rounded
												text
												raised
												severity="warning"
												tooltip="Delete"
												disabled={!!currentEditItem}
												onClick={(event) => {
													event.preventDefault()
													if (currentEditItem) {
														return
													}
													setRequestInfo((prev) => {
														return {
															...prev,
															buttonName: String(`delete-button-${values?.id}`),
															visible: true,
														}
													})
												}}
												data-pr-tooltip="Delete"
												data-pr-position="bottom"
											/>
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	)
}

export default ShiftConfigCard
